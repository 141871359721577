import React, { useEffect, useState } from "react";
import { Autocomplete, Button, Grid, TextField, IconButton, Checkbox, FormControlLabel, Divider, Select, ListItemText, InputLabel, FormControl, MenuItem } from "@mui/material";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ObjectID from "bson-objectid";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useCreateDataMutation } from "../../api/apiSliceV2";
import { setSelectedItem } from "../stepper/stepperSlice";
import DeleteIcon from '@mui/icons-material/Delete';
import CustomButton from "../button/CustomButton";

const FormComponent = (props: any) => {
    const {
        step,
        columns,
        setCompleted,
        activeStep,
        autocompleteOptions,
        onJobTypeChange,
        onCompanyChange,
        tagName, 
        isMultiple = true,
        jobType, 
        selectedSov, 
        handleSovChange,
        sovIDs
    } = props;

    const dispatch = useAppDispatch();
    const { selectedItem, formErrors } = useAppSelector((state: any) => state.stepper);
    const { company, division } = useAppSelector((state: any) => state.auth);
    const { project_id, budget_id, proposal_id, contract_id } = useParams();
    const [formDataList, setFormDataList] = useState<any[]>([]);
    const [createData] = useCreateDataMutation();

    const excludedFields = [
        '_id', '_v', "__v", 'job_id', 'company_id', 'project_id', 'budget_id', 'proposal_id', 'contract_id',
        'created_by', 'created_at', 'updated_by', 'updated_at', "items", "budget_items", "order_items",
        "subjob_number", "builders", 'job_type_id',
    ];

    const disabledFields = [
      ""
    ];

    const translationRequired = [
        "proposal_reference", "budget_reference", "company_id", "division_id", "customer_id", "builders",
        "customers", "job_types", "job_type", "job_type_id", "supervisor", "estimator", "foreman", "jc_accountant"
    ];

    useEffect(() => {
        const initialFormData: any = {};
        columns?.filter((column: any) => !excludedFields.includes(column?.field)).forEach((column: any) => {
            if(sovIDs){
                initialFormData['schedule_of_values_id'] = '';
            }
            if (column.type === 'date') {
                initialFormData[column.field] = dayjs();
            } else {
                initialFormData[column.field] = '';
            }
        });
        setFormDataList([initialFormData]);
    }, [columns, project_id]);

    const handleChange = (index: number, e: any) => {
        const { name, value } = e.target;
        setFormDataList((prevState: any[]) => {
            const newFormData = [...prevState];
            newFormData[index] = { ...newFormData[index], [name]: value };
            return newFormData;
        });
    };

    const handleDateChange = (index: number, field: string, newValue: any) => {
        setFormDataList((prevState: any[]) => {
            const newFormData = [...prevState];
            newFormData[index] = { ...newFormData[index], [field]: newValue };
            return newFormData;
        });
    };

    const handleAddItem = () => {
        const newFormData: any = {};
        columns?.forEach((column: any) => {
            if (column.type === 'date') {
                newFormData[column.field] = dayjs();
            } else {
                newFormData[column.field] = '';
            }
        });
        setFormDataList((prevState) => [...prevState, newFormData]);
    };

    const handleRemoveItem = (index: number) => {
        setFormDataList((prevState) => prevState.filter((_, i) => i !== index));
    };

    const handleSave = async () => {
        try {
            let body = formDataList?.map((formData: any) => translateIds({
                ...formData,
                _id: new ObjectID(),
                company_id: company?._id,
                division_id: division?._id,
                job_type_id: jobType?._id,
                project_id: step?.idType === 'project_id' ? project_id : undefined,
                schedule_of_values_id: sovIDs?.get(formData?.schedule_of_values_id)?._id || formData?.schedule_of_values_id,
            }));;
            const id = (step?.idType === 'project_id') ? project_id : (budget_id || proposal_id || contract_id || selectedItem?._id);
            const res = await createData({
                tagName,
                url: step?.saveUrl(id),
                body: isMultiple ? body : body[0],
            }).unwrap();
            dispatch(setSelectedItem(res?.data));

            setCompleted({ [activeStep]: true });
        } catch (err: any) {
            console.log(err)
        }
    };

    const translateIds = (jsonData: any) => {
        try {
            for (const [key, val] of Object.entries(jsonData)) {
                if (translationRequired.includes(key)) {
                    switch (key) {
                        case "proposal_reference":
                            const proposal = autocompleteOptions?.[key]?.data?.find((proposal: any) => `${proposal?.proposal_name} - ${proposal?.proposal_rev_no}` === val);
                            jsonData[key] = proposal?._id || val;
                            break;
                        case "budget_reference":
                            const budget = autocompleteOptions?.[key]?.data?.find((budget: any) => `${budget?.budget_name} - ${budget?.budget_rev_no}` === val);
                            jsonData[key] = budget?._id || val;
                            break;
                        case "company_id":
                            const co = autocompleteOptions?.[key]?.data?.find((co: any) => co?.company_number === val) || company;
                            jsonData[key] = co?._id || val;
                            break;
                        case "division_id":
                            const div = autocompleteOptions?.[key]?.data?.data?.find((d: any) => d?.division_number === val) || division;
                            jsonData[key] = div?._id || val;
                            break;
                        case "customer_id":
                            const customer = autocompleteOptions?.[key]?.data?.find((c: any) => c?.customer_description === val);
                            jsonData[key] = customer?._id || val;
                            break;
                        case "supervisor":
                            const supervisor = autocompleteOptions?.[key]?.data?.find((s: any) => s?.employee_name === val);
                            jsonData[key] = supervisor?._id || undefined;
                            break;
                        case "foreman":
                            const foreman = autocompleteOptions?.[key]?.data?.find((f: any) => f?.employee_name === val);
                            jsonData[key] = foreman?._id || undefined;
                            break;
                        case "estimator":
                            const estimator = autocompleteOptions?.[key]?.data?.find((e: any) => e?.employee_name === val);
                            jsonData[key] = estimator?._id || undefined;
                            break;
                        case "jc_accountant":
                            const jc_accountant = autocompleteOptions?.[key]?.data?.find((j: any) => j?.employee_name === val);
                            jsonData[key] = jc_accountant?._id || undefined;
                            break;
                        case "job_type":
                            const translated = autocompleteOptions?.[key]?.data?.find((j: any) => j?.job_type_description === jsonData["job_type"]);
                            jsonData["job_type_id"] = translated?._id;
                            break;
                        case "job_types":
                            if (Array.isArray(val)) {
                                const translatedJobTypes = val?.map((job_type) => {
                                    const translatedJobType = autocompleteOptions?.[key]?.data?.find((j: any) => j?.job_type_description === job_type);
                                    return translatedJobType?._id || job_type;
                                });
                                jsonData[key] = translatedJobTypes;
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
        } catch (error) {
            console.error("Error translating IDs:", error);
        }
        return jsonData;
    };

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ my: 1 }}>
            <Grid item xs={12} sx={{ width: "100%"}}>
                {isMultiple && (
                    <CustomButton variant="text" size="small" onClick={handleAddItem} color="primary">Add Item</CustomButton>
                )}
            </Grid>
            <Divider flexItem sx={{ width: "100%", borderColor: "#e0e0e0", my: 1 }} />
            <Grid item xs={12} >
                {formDataList.map((formData, index) => (
                    <Grid 
                        container 
                        justifyContent="flex-start" 
                        alignItems="center" 
                        sx={{ width: "auto"}} 
                        spacing={1} 
                        key={index}
                    >
                        {isMultiple && <Grid item xs='auto' sx={{ display: 'flex', justifyContent: 'flex-end', mr: 1 }}>
                            <IconButton onClick={() => handleRemoveItem(index)} color="primary">
                                <DeleteIcon />
                            </IconButton>
                        </Grid>} 
                        {columns && columns.length > 0 &&
                            columns.filter((column: any) => column?.type !== 'actions' &&
                                !excludedFields.includes(column?.field)).map((column: any) => (
                            <Grid item xs='auto' key={column?.field}>
                                {column?.type === 'date' ? (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            sx={{ width: "100%" }}
                                            formatDensity="dense"
                                            label={column?.headerName}
                                            defaultValue={dayjs()}
                                            value={formData[column?.field]}
                                            onChange={(newValue) => handleDateChange(index, column?.field, newValue)}
                                        />
                                    </LocalizationProvider>
                                ) : column?.type === 'boolean' ? (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData[column?.field] || false}
                                                onChange={(e) => handleChange(index, {
                                                    target: {
                                                        name: column?.field,
                                                        value: e.target.checked
                                                    }
                                                })}
                                                name={column?.field}
                                                size="small"
                                            />
                                        }
                                        label={column?.headerName}
                                    />
                                ) : autocompleteOptions?.[column?.field] ? (
                                    <Autocomplete
                                        freeSolo
                                        multiple={column?.type === 'array'}
                                        fullWidth
                                        sx={{ minWidth: 200 }}
                                        options={autocompleteOptions?.[column?.field]?.options || autocompleteOptions?.[`${column?.field}s`]?.options || []}
                                        getOptionLabel={(option) => option}
                                        size="medium"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={column?.headerName}
                                                variant="outlined"
                                                error={formErrors && !!formErrors?.[column?.field]}
                                                helperText={formErrors && formErrors[column?.field] ? 'required' : ''}
                                            />
                                        )}
                                        value={formData[column?.field] || (column.type === "array" ? [] : "")}
                                        onChange={(_, newValue: string) => {
                                            if ((column.field === 'job_type' || column.field === 'job_type_id') && onJobTypeChange) {
                                                onJobTypeChange(newValue);
                                            }
                                            if (column.field === 'company_id' && onCompanyChange) {
                                                onCompanyChange(newValue);
                                            }
                                            setFormDataList((prevState: any[]) => {
                                                const newFormData = [...prevState];
                                                newFormData[index] = {
                                                    ...newFormData[index],
                                                    [column?.field]: column.type === 'array'
                                                        ? newValue
                                                        : newValue || "",
                                                };
                                                return newFormData;
                                            });
                                        }}
                                    />
                                ) : (
                                    <TextField
                                        label={column?.headerName}
                                        disabled={disabledFields.includes(column?.field)}
                                        fullWidth
                                        type={column?.type === 'number' ? 'number' : 'text'}
                                        name={column?.field}
                                        value={formData[column?.field] || ''}
                                        onChange={(e) => handleChange(index, e)}
                                        variant="outlined"
                                        error={formErrors && !!formErrors?.[column?.field]}
                                        helperText={formErrors && formErrors?.[column?.field] ? 'required' : ''}
                                    />
                                )}
                            </Grid>
                        ))}
                        
                        <Divider flexItem sx={{ width: "100%", borderColor: "#e0e0e0", my: 1 }} />
                    </Grid>
                ))}
                <Grid container justifyContent="flex-end" sx={{ marginTop: "1em" }} gap={1}>
                    <CustomButton variant="contained" size="small" onClick={handleSave} color="primary">Save</CustomButton>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default FormComponent;
