import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { showSnackbar } from '../components/snackbar/snackbarSlice';
import { logout, redirectToLogin } from '../auth/authSlice'; // Import the logout action
import { Mutex } from 'async-mutex';

// Create a mutex for handling token refresh
const mutex = new Mutex();

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && (result.error.status === 401 || result.error.status === 403)) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        // Dispatch logout action
        api.dispatch(logout());
        api.dispatch(redirectToLogin());
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include'
});

export const apiSliceV2 = createApi({
  reducerPath: 'apiV2',
  baseQuery: baseQueryWithReauth, // Use the new baseQuery with reauth logic
  tagTypes: [''],  
  endpoints: (builder) => ({
    getData: builder.query({
      query: ({ url, params }) => ({
        url,
        params,
      }),
      transformErrorResponse: async (response, meta, arg) => {
        return response.status === 404 ? "Not Found" : response?.data || "An error occurred";
      },
      providesTags: (result, error, { id, tagName }) => [
        { type: tagName || 'Data', id },
      ],
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(showSnackbar({ message: response?.message || 'Operation successful', type: 'success' }));
        } catch (error) {
          const errorMessage = 'Data failed to load, please try again';
          dispatch(showSnackbar({ message: errorMessage, type: 'error' }));
        }
      },
    }),
    createData: builder.mutation({
      query: ({ url, body }) => ({
        url,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { id, tagName }) => {  if (error) return [];  if (Array.isArray(tagName)) {    return tagName.map((tag) => ({ type: tag, id }));  }  return [{ type: tagName, id }];},
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(showSnackbar({ message: response?.message || 'Operation successful', type: 'success' }));
        } catch (error) {
          const errorMessage = 'Failed to create data, please try again';
          dispatch(showSnackbar({ message: errorMessage, type: 'error' }));
        }
      },
    }),
    updateData: builder.mutation({
      query: ({ url, body }) => ({
        url,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id, tagName }) => {  if (error) return [];  if (Array.isArray(tagName)) {    return tagName.map((tag) => ({ type: tag, id }));  }  return [{ type: tagName, id }];},
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(showSnackbar({ message: response?.message || 'Operation successful', type: 'success' }));
        } catch (error) {
          const errorMessage = 'Failed to update data, please try again';
          dispatch(showSnackbar({ message: errorMessage, type: 'error' }));
        }
      },
    }),
    deleteData: builder.mutation({
      query: ({ url, ids }) => {
        if (ids && Array.isArray(ids)) {
          return { url, body: { ids }, method: 'DELETE' };
        }
        return { url, method: 'DELETE' };
      },
      invalidatesTags: (result, error, { id, tagName }) => {  if (error) return [];  if (Array.isArray(tagName)) {    return tagName.map((tag) => ({ type: tag, id }));  }  return [{ type: tagName, id }];},
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(showSnackbar({ message: response?.message || 'Data deleted successfully', type: 'success' }));
        } catch (error:any) {
          const errorMessage = error?.error?.message || 'Failed to delete data, please try again';
          dispatch(showSnackbar({ message: errorMessage, type: 'error' }));
        }
      },
      transformErrorResponse: (response, meta, arg) => {
        if ('status' in response) {
          const errorMessage = (response.data as { message?: string })?.message;
          return { status: response.status, message: errorMessage };
        } else {
          return { status: 'unknown', message: response || 'An unknown error occurred' };
        }
      },
    }),
    uploadData: builder.mutation({
      query: ({ url, body }) => {
        return { url, method: 'POST', body};
      },
      invalidatesTags: (result, error, { id, tagName }) => {
        if (error) return [];
        return [{ type: tagName, id }];
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(showSnackbar({ message: response?.message || 'Upload successful', type: 'success' }));
        } catch (error) {
          const errorMessage = 'Failed to upload data, please try again';
          dispatch(showSnackbar({ message: errorMessage, type: 'error' }));
        }
      },
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetDataQuery,
  useCreateDataMutation,
  useUpdateDataMutation,
  useDeleteDataMutation,
  useUploadDataMutation
} = apiSliceV2;
