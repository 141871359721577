import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { TagTypes } from "../../api/tagTypes";
import { DetailConfig } from "../../components/interfaces/DetailConfig";
import { whMaterialPricingColumns } from "./columns";
import warehouseSteps from "../steps/steps";
import CustomGridToolbar from "../../components/grid/toolbars/CustomGridToolbar";
import DetailPanel from "../../projects/project-items/sov-comparison/DetailPanel";

const useWarehouseMaterialConfig = () => {
    const {start_id} = useParams();
 
    const tabs = useMemo(() => {
        return [
            {
                index: 0,
                route: '/warehouse-materials', 
                tagType: TagTypes.WAREHOUSEORDERS,
                params: undefined,
                label: 'Warehouse Materials',
                header: false,
                steps: warehouseSteps?.addWarehouseMaterial,
                detail: {
                    columns: whMaterialPricingColumns,
                    toolbar: CustomGridToolbar,
                    toolbarProps: {},
                    footer: null,
                    footerProps: {},
                    actions: {
                        expandable: true,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
                panel: {
                    component: DetailPanel,
                    hasDocuments: true
                }
            },
        ]
    }, []);

    return {
        tabs
    };
};

export default useWarehouseMaterialConfig;