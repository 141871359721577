import { Box } from '@mui/material';
import { GridToolbarContainer} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import WarehouseSearchModal from '../warehouse-search/WarehouseSearchModal';
import { useLocation } from 'react-router-dom';
import FulFillOrder from './actions/FulFillOrder';
import RejectOrder from './actions/RejectOrder';
import RequestOrder from './actions/RequestOrder';

interface WarehouseSearchToolbarProps {
    row: any;
    type: string;
    tagName: string;
    action: boolean;
}


const WarehouseSearchToolbar: React.FC<WarehouseSearchToolbarProps> = (props: any) => {
    const location = useLocation();
    const [route, setRoute] = useState<string>("");

    useEffect(() => {
        setRoute(location.pathname.split("/")[3] || location.pathname.split("/")[1])
    },[location.pathname]);
    
    return(
        <Box sx={{display: "flex", justifyContent: "flex-start", height: "auto", margin: 1}}>
            <GridToolbarContainer>
                {props?.action && 
                    <WarehouseSearchModal {...props} />
                }
                {props?.action && route !== "projects" && (
                    <>
                        {route === "warehouse-orders" ? (
                            <>
                                <FulFillOrder {...props} />
                                <RejectOrder {...props} />
                            </>
                        ) : (
                            <>
                                <RequestOrder {...props} />
                            </>
                        )}
                    </>
                )}
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        </Box>
        
    )
}

export default WarehouseSearchToolbar;
