import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, TextField, InputAdornment } from "@mui/material";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { clearFormErrors } from '../../projects/projectSlice';
import { 
  DataGridPremium, 
  GridEventListener, 
  GridRowEditStopReasons, 
  GridRowId, 
  GridRowModes, 
  GridRowModesModel, 
  GridRowSelectionModel, 
  useGridApiRef 
} from '@mui/x-data-grid-premium';
import ObjectID from 'bson-objectid';
import { useParams } from 'react-router-dom';
import { setErrorState } from '../stepper/stepperSlice';
import { useCreateDataMutation } from '../../api/apiSliceV2';
import { showSnackbar } from '../snackbar/snackbarSlice';
import SelectMenu from './SelectMenu';
import { requiredFields } from './SelectForm';


const Clipboard = ({
  tagName, 
  options, 
  step, 
  columns, 
  activeStep, 
  completed, 
  handleClose, 
  setCompleted, 
  jobType, 
  selectedSov, 
  setSelectedSov,
  sovIDs
}: any) => {
  const { selectedItem, isLoading } = useAppSelector((state: any) => state.stepper);
  const { project_id, budget_id, proposal_id, contract_id } = useParams();
  const [rows, setRows] = useState<any[]>([]);
  const context = step?.route?.split("/")[1];
  const [numRowsToAdd, setNumRowsToAdd] = useState<number>(1);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const { company, division } = useAppSelector((state: any) => state.auth);
  const dispatch = useAppDispatch();
  const apiRef = useGridApiRef();
  const [createData] = useCreateDataMutation();

  useEffect(() => {
    const checkPermissions = async () => {
      const queryOpts: any = { name: 'clipboard-read', allowWithoutGesture: false };
      const permissionStatus: any = await navigator.permissions.query(queryOpts);
      if (permissionStatus && permissionStatus.state === 'prompt') {
        navigator.clipboard.readText().catch((error: any) => console.log(error));
      }
    }
    checkPermissions();
  }, []);

  const addItem = () => {
    const newRow = {
      _id: new ObjectID().toHexString(),
    };
    setRows((currentRows: any) => [...currentRows, newRow]);
  };

  const addMultipleItems = () => {
    for (let i = 0; i < numRowsToAdd; i++) {
      addItem();
    }
  };

  useEffect(() => {
    addMultipleItems();
  }, []);

  const handleEdit = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = useCallback((newRow: any) => {
    setRows((rows: any) => rows.map((row: any) => (row._id === newRow._id) ? newRow : row));
    return newRow;
  }, []);

  const handleRowModesModelChange = (rowModesModel: GridRowModesModel) => {
    setRowModesModel(rowModesModel);
    console.log(rowModesModel)
  }

  const cancelAllEditModes = useCallback(() => {
    const rowIds = apiRef.current.getAllRowIds();
    rowIds.forEach((rowId: GridRowId) => {
      const rowMode = apiRef.current.getRowMode(rowId);
      if (rowMode === GridRowModes.Edit) {
        apiRef.current.stopRowEditMode({ id: rowId });
      }
    });
  }, [apiRef]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      cancelAllEditModes();
      await new Promise(resolve => setTimeout(resolve, 0));
      const currentRows = apiRef.current.getRowModels();
      const filtered = Array.from(currentRows.values()).map((row: any) => {
        const key = `${row?.plan}-${row?.elevation}-${row?.option}`.toUpperCase();
        const schedule_of_values_id = sovIDs?.get(key);

        //if sov key is not found, create the sov item
        return {
          project_id,
          company_id: company?._id,
          division_id: division?._id,
          schedule_of_values_id,
          job_type_id: jobType?._id,
          ...row
        };
      }).filter((row: any) => {
        const fields = requiredFields?.[context];
        if (!fields) return false;
        return fields.every((field: string) => {
          const value = row[field];
          return value !== undefined && value !== null && value !== '';
        });
      });

      if (filtered.length === 0) {
        dispatch(setErrorState(true));
        dispatch(showSnackbar({ message: "Submission failed, no data provided.", type: "error" }));
        return;
      }

      let id: string;
      switch (step?.idType) {
        case 'project_id':
          id = project_id || "";
          break;
        case 'budget_id':
          id = budget_id || selectedItem?._id;
          break;
        case 'proposal_id':
          id = proposal_id || selectedItem?._id;
          break;
        case 'contract_id':
          id = contract_id || selectedItem?._id;
          break;
        default:
          id = project_id || "";
          break;
      }

      const submitBatch = async (batch: any) => {
        const response = await createData({
          url: step?.saveUrl(id),
          body: batch,
          tagName
        }).unwrap();
        return response;
      }

      const batchSize = 100;
      for (let i = 0; i < filtered?.length; i += batchSize) {
        const batch = filtered?.slice(i, i + batchSize);
        await submitBatch(batch);
      }

      dispatch(showSnackbar({ message: 'Data created successfully', type: 'success' }));
      setCompleted({ [activeStep]: true });
      dispatch(clearFormErrors());
    } catch (error) {
      console.log("error", error)
      dispatch(showSnackbar({ message: "Batch submission failed, please review the data for errors.", type: "error" }));
    }
  };

  const handlePasteStart = async () => {
    try {
      apiRef.current.selectRows(rows?.map((row: any) => row?._id));
    } catch (error) {
      console.log("Error selecting before clipboard paste")
    }
  }

  const handlePasteEnd = async () => {
    try {
      setRows(rows?.filter((row: any) => {
        if ((context in requiredFields)) {
          for (const field of requiredFields?.[context]) {
            if (!(field in row)) return false;
          }
        }
        return true;
      }));

    } catch (error) {
      console.log(error)
    }
  }

  const handleApplySov = useCallback(() => {
    const sov = sovIDs?.get(selectedSov);
    if (!sov) return;

    setRows((prevRows: any[]) =>
      prevRows.map((row: any) => {
        if (rowSelectionModel.includes(row?._id)) {
          return {
            ...row,
            schedule_of_values_id: sov?._id,
            plan: sov?.plan,
            elevation: sov?.elevation,
            option: sov?.option,
            option_code: sov?.option_code,
          };
        }
        return row;
      })
    );

    // Force the grid to refresh
    apiRef.current.updateRows(rows);
  }, [rowSelectionModel, selectedSov, sovIDs, rows]);

  const clearRows = () => {
    setRows([]);
  }

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Grid container justifyContent="center" sx={{ width: "100%" }}>
        <Grid container sx={{ my: 1, py: 1 }} gap={0}>
          <Grid item xs="auto">
            <TextField
              sx={{ width: 220}}
              label="Add/Remove Rows"
              type="number"
              size="small"
              value={numRowsToAdd}
              onChange={(e) => setNumRowsToAdd(parseInt(e.target.value))}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      sx={{ textAlign: "right", fontWeight: 600, justifyContent: "flex-end" }}
                      variant="text"
                      size="small"
                      color="primary"
                      onClick={addMultipleItems}
                    >
                      Add
                    </Button>
                    <Button
                      sx={{ textAlign: "right", fontWeight: 600, justifyContent: "flex-end" }}
                      variant="text"
                      size="small"
                      color="error"
                      onClick={clearRows}
                      disabled={rows?.length === 0}
                    >
                      Clear
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {step?.options?.sov && (
            <Grid item xs="auto">
              <SelectMenu options={Array.from(sovIDs.keys())} label="Select Schedule of Values" selectedValue={selectedSov} onChange={(selected: string) => setSelectedSov(selected)} onApply={handleApplySov} />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} sx={{ height: "auto", maxHeight: "60vh" }}>
          <DataGridPremium
            sx={{
              fontSize: 14,
              fontWeight: 500,
              border: "1px solid rgba(0,0,0,0.25)"
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  subjob_and_job_type: false,
                  job_type_id: false,
                  schedule_of_values_id: false,
                  document: false,
                  created_at: false,
                  created_by: false,
                  updated_at: false,
                  updated_by: false
                },
              },
            }}
            checkboxSelection
            onRowSelectionModelChange={(selected: any) => setRowSelectionModel(selected)}
            apiRef={apiRef}
            editMode="row"
            getRowId={row => row?._id}
            density="compact"
            rows={rows || []}
            columns={columns || []}
            loading={isLoading}
            rowHeight={60}
            rowBuffer={10}
            rowModesModel={rowModesModel}
            rowSelectionModel={rowSelectionModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            experimentalFeatures={{ clipboardPaste: true }}
            onClipboardPasteStart={handlePasteStart}
            onClipboardPasteEnd={handlePasteEnd}
            unstable_ignoreValueFormatterDuringExport
          />
        </Grid>

        <Grid item xs={12} justifyContent="flex-end" sx={{ my: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>

            {!completed[activeStep] &&
              <Button sx={{ marginLeft: 1, fontWeight: 600 }} variant="contained" onClick={(event: any) => handleSubmit(event)} component="label">
                Create items
              </Button>}
            {completed[activeStep] &&
              <Button sx={{ marginLeft: 1, fontWeight: 600 }} variant="contained" onClick={handleClose} component="label">
                Close
              </Button>}
          </Box>

        </Grid>

      </Grid>
    </form>
  );
};

export default Clipboard;
