import appAPI from '../api/appAPI';

//`/dropdowns/starts/lots?company_number=${req?.company_number}&job_number=${req?.job_number}`
const getDropdowns = async (req:any) => {
    const url = req?.url;
    const response = await appAPI.get(url);
    return response.data;
}

const apiService = {
    getDropdowns
}

export default apiService