import cemexAPI from '../api/cemexAPI';
import appAPI from '../api/appAPI';
import fileDownload from 'js-file-download';

const getTargetInstance = (service:string) => {
    switch(service){
        case "cemex":
            return cemexAPI;
        case "app":
            return appAPI;
        default:
            return appAPI;
    }
}
// Get user projects
const getJobCostReport = async (req:any) => {

    const url = req.url;
    const instance = getTargetInstance(req.service);
    const response = req?.data ? await appAPI.get(`/reports/cost-report?${url}`, req?.data)  : await appAPI.get(`/reports/cost-report?${url}`);
    
    return response.data;
}

const getProjectTemplate = async (req:any) => {

    const url = req.url;
    const instance = getTargetInstance(req.service);
    const response = await appAPI.get(`/projects-template`, {responseType: 'blob'});
    return response.data;
}

const getOrdProcEnt = async (req:any) => {
    const url = req.url;
    const instance = getTargetInstance(req.service);
    const response = await instance.put(`/reports/ordprocent/export?${url}`, req?.data);
    return response.data;
}

const getJobCostReportByStartIds = async (req:any) => {
    const url = req.url;
    const instance = getTargetInstance(req.service);
    const response = await instance.put(`/reports/cost-report/export?${url}`, req?.data);
    return response.data;
}

const getCiImportByStartIds = async (req:any) => {
    const url = req.url;
    const instance = getTargetInstance(req.service);
    const response = await instance.put(`/reports/contract-items/export?${url}`, req?.data);
    return response.data;
}

const getContractItems = async (req:any) => {

    const url = req.url;
    const instance = getTargetInstance(req.service);
    const response = await appAPI.get(`/reports/contract-items?${url}`);
    return response.data;
}

const apiService = {
    getContractItems,
    getProjectTemplate,
    getJobCostReport,
    getJobCostReportByStartIds,
    getCiImportByStartIds, 
    getOrdProcEnt
}

export default apiService