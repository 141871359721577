import {ReactComponent as Logo} from '../../assets/test.svg';
import {ReactComponent as StartSuiteLogo} from '../../assets/sss.svg';
import {ReactComponent as ConcreteOrdering} from '../../assets/ConcreteOrdering.svg';
import {ReactComponent as Aggregates} from '../../assets/aggregates.svg';
import {ReactComponent as Warehouse} from '../../assets/warehouse2.svg';
import {ReactComponent as Materials} from '../../assets/warehouse-materials.svg';
import {ReactComponent as OrgUnits} from '../../assets/organizational-units.svg';
import InventorySharpIcon from '@mui/icons-material/InventorySharp';
import ConstructionSharpIcon from '@mui/icons-material/ConstructionSharp';
import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';
import PriceCheckSharpIcon from '@mui/icons-material/PriceCheckSharp';
import ReceiptLongSharpIcon from '@mui/icons-material/ReceiptLongSharp';
import ObjectID from "bson-objectid";
import WarehouseSharpIcon from '@mui/icons-material/WarehouseSharp';
import LanSharpIcon from '@mui/icons-material/LanSharp';
import { URLS } from '../routes/urls';

export const pageLinks = {
    admin: [
        {id: String(new ObjectID()), title: 'Organizational Units', desc: "Manage Organizational Units", imgSrc: OrgUnits, link: URLS.organizationalUnits, icon: <LanSharpIcon fontSize="large" />},
    ],
    app: [
        {id: String(new ObjectID()), title: 'Projects', desc: "Manage Projects", imgSrc: Logo, link: URLS.projects, icon: <ConstructionSharpIcon fontSize="large" />},
        {id: String(new ObjectID()), title: 'Starts', desc: "Manage Subjobs", imgSrc: StartSuiteLogo,link: URLS.starts, icon: <PlayArrowSharpIcon fontSize="large" />},
        {id: String(new ObjectID()), title: 'Concrete Pricing', desc: "Manage Concrete Mix Designs & Prices", imgSrc: ConcreteOrdering,link: URLS.concreteMixDesignPricing, icon: <PriceCheckSharpIcon fontSize="large" />},
        {id: String(new ObjectID()), title: 'Aggregate Pricing List', desc: "Manage Aggregate Supplier Quote List", imgSrc: Aggregates,link: URLS.aggregatePricing, icon: <InventorySharpIcon fontSize="large" />},
    ],
    warehouse: [
        {id: String(new ObjectID()), title: 'Warehouse Orders', desc: "View Warehouse Orders", imgSrc: Warehouse, link: URLS.warehouseOrders, icon: <WarehouseSharpIcon fontSize="large" />},
        {id: String(new ObjectID()), title: 'Warehouse Materials List', desc: "Manage Materials List", imgSrc: Materials, link: URLS.warehouseMaterialPricing, icon: <ReceiptLongSharpIcon fontSize="large" />},
    ]
}
