import { GridColDef } from "@mui/x-data-grid-premium";

export const whMaterialPricingColumns: GridColDef[] = [
  {
    field: 'part_number',
    headerName: 'Part Number',
    sortable: true,
    type: 'string',
    width: 120,
    editable: true,
  },
  {
    field: 'part_description',
    headerName: 'Description',
    width: 260,
    type: 'string',
    sortable: true,
    editable: true
  },
  {
    field: 'cost_code',
    headerName: 'Cost Codes',
    type: 'string',
    sortable: true,
    width: 160,
    editable: true,
  },
  {
    field: 'cost_type',
    headerName: 'Cost Type',
    type: 'string',
    sortable: true,
    width: 160,
    editable: true,
  },
  {
    field: 'unit_of_measure',
    headerName: 'Unit of Measure',
    type: 'string',
    width: 80,
    editable: true,
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    headerAlign: "left",
    align: "left",
    type: 'date',
    width: 120,
  filterable: true,
  editable: false,
  valueGetter: (params: any) => params.value ? new Date(params.value) : undefined,
  valueFormatter: (params: any) => {
    if (!params.value) {
      return '';
    }
    const date = new Date(params.value);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  },
},
{
  field: 'created_by',
  headerName: 'Created By',
  headerAlign: "left",
  align: "left",
  type: 'string',
  width: 160,
  filterable: true,
  editable: false,
},
{
  field: 'updated_at',
  headerName: 'Updated At',
  headerAlign: "left",
  align: "left",
  type: 'date',
  width: 120,
  filterable: true,
  editable: false,
  valueGetter: (params: any) => params.value ? new Date(params.value) : undefined,
  valueFormatter: (params: any) => {
    if (!params.value) {
      return '';
    }
    const date = new Date(params.value);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  },
},
{
  field: 'updated_by',
  headerName: 'Updated By',
  headerAlign: "left",
  align: "left",
  type: 'string',
  width: 160,
  filterable: true,
  editable: false,
}
];