
import { Button } from "@mui/material";

const ExpandAllButton = (props:any) => {
    const {expandedRowIds, rows, setExpandedRowIds} = props;
    const isExpanded = expandedRowIds.length > 0;
    const handleExpandAll = () => { 
        setExpandedRowIds(isExpanded ? [] : rows.map((row:any) => row._id));
    }
    const handleCollapseAll = () => {
        setExpandedRowIds([]);
    }
    return <Button {...props} sx={{fontWeight: 600}} size="small" variant="text" onClick={isExpanded ? handleCollapseAll : handleExpandAll}>{isExpanded ? "Collapse All" : "Expand All"}</Button>
}

export default ExpandAllButton;     