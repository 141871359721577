import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarehouseSearch from './WarehouseSearch'; // Assuming WarehouseSearch is in the same directory
import CustomButton from '../../components/button/CustomButton';

const WarehouseSearchModal = (props:any) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <CustomButton 
        variant='contained'  
        color="primary" 
        size="small" 
        onClick={handleOpen} 
        sx={{
          fontWeight: 700,
          color: "white"
        }}
      >
        Add materials
      </CustomButton>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle sx={{textAlign: "center"}}>
          <Typography color="success" variant="h4" sx={{ textTransform: 'uppercase' }}>
            {props?.orderType} {props?.order?.order_number}
          </Typography>


          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{mt: 2}}>
          <WarehouseSearch handleClose={handleClose} {...props} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default WarehouseSearchModal;
