import React, { Suspense, lazy, useCallback, useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { setNavigator } from '../../utils/navigator';
import Spinner from '../spinner/Spinner';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import useRouteList from '../routes/useRouteList';
import { logout, reset } from '../auth/authSlice';
import { currentAuthenticatedUser } from '../auth/authSlice';

const useAuth = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
  
    const checkAuth = useCallback(async () => {
      try {
        await dispatch(currentAuthenticatedUser()).unwrap();
      } catch (error) {
        await dispatch(logout());
        dispatch(reset());
        navigate('/login');
      }
    }, [dispatch, navigate]);
  
    return { checkAuth };
  };

// Lazy load components
const NotFound = lazy(() => import('../components/NotFound'));
const PrivateRoute = lazy(() => import('../routes/PrivateRoute'));
const Login = lazy(() => import('../auth/Login'));
const PasswordReset = lazy(() => import('../auth/PasswordReset'));

const MainContent = () => {
    const navigate = useNavigate();
    setNavigator(navigate);
    const { checkAuth } = useAuth();
    const { isAuthenticated, isLoading } = useAppSelector((state: any) => state.auth);
    const filteredRoutes = useRouteList();


    if (isLoading) {
        return <Spinner />;
    }

    return (
        <Box sx={{ height: "100%", width: "100%" }}>
            <Suspense fallback={<Spinner />}>
                <Routes>
                    {/* Public Routes */}
                    <Route path="/login" element={
                        isAuthenticated ? <Navigate to="/" replace /> : <Login />
                    } />
                    <Route path="/password-reset" element={<PasswordReset />} />

                    {/* Protected Routes */}
                    {isAuthenticated && filteredRoutes?.map(({ component: Component, ...route }) => (
                        <Route
                            key={route.id}
                            path={route.path}
                            element={
                                <PrivateRoute>
                                    <Component />
                                </PrivateRoute>
                            }
                        />
                    ))}

                    {/* Catch-all route */}
                    <Route path="*" element={
                        isAuthenticated ? <NotFound /> : <Navigate to="/login" replace />
                    } />
                </Routes>
            </Suspense>
        </Box>
    );
}

export default MainContent;