import React from "react";
import useWarehouseMaterialConfig from "./userWarehouseMaterialConfig";
import DetailView from "../../components/detail-view/Detail";
const WarehouseMaterialsDetail = () => {
  const {tabs}: any = useWarehouseMaterialConfig();
  return <DetailView tabs={tabs} />
}

export default WarehouseMaterialsDetail;

