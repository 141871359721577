import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../../app/hooks';
import CustomButton from '../button/CustomButton';

const ConfirmationDelete = (props:any) => {
    const { row, open, handleDelete, setOpen } = props;
    const theme = useTheme();
    const {isMobile} = useAppSelector((state:any) => state.layout);

    const excluded = [
        '_id',
        '_v',
        '__v',
        'project_id',
        'budget_id',
        'proposal_id',
        'contract_id',
        'items',
        'budget_items',
        'proposal_items',
        'contract_items',
        'order_items'
    ];

    const handleYesClick = () => {
        const id = row?._id || row?.id || row?.item_id;
        handleDelete(id);
        setOpen((open:boolean) => !open);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const ValueDisplay = ({ value, indent = 0 }: { value: any; indent?: number }) => {
      const renderValue = (val: any, key?: string, currentIndent: number = 0) => {
        if (val === null || val === undefined) {
          return (
            <Typography
              fontWeight={500}
              sx={{
                marginLeft: currentIndent * 1,
                color: theme.palette.primary.main,
                whiteSpace: "normal",
                wordBreak: "break-word",
                overflowWrap: "break-word",
              }}
            >
              {key ? `${key}: ` : ''}N/A
            </Typography>
          );
        }

        if (typeof val === 'object' && !Array.isArray(val)) {
          return (
            <Box>
              {key && (
                <Typography
                  fontWeight={500}
                  sx={{
                    marginLeft: currentIndent * 1,
                  }}
                >
                  {key}:
                </Typography>
              )}
              {Object.entries(val).map(([nestedKey, nestedVal]) => (
                <Box key={nestedKey}>
                  {renderValue(nestedVal, nestedKey, currentIndent + 1)}
                </Box>
              ))}
            </Box>
          );
        }

        return (
          <Typography
            fontWeight={500}
            sx={{
              marginLeft: currentIndent * 1,
              color: theme.palette.primary.main,
              whiteSpace: "normal",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {key ? `${key}: ` : ''}{String(val)}
          </Typography>
        );
      };

      return renderValue(value, undefined, indent);
    };
        
    return (
        <Dialog 
            fullWidth 
            onClose={handleClose} 
            open={open} 
        >
            <DialogTitle textAlign="center" fontWeight={700}>Are you sure?</DialogTitle>
            <DialogContent  dividers>
                <Box 
                    sx={{ 
                        display: "flex", 
                        flexDirection: isMobile ? "column" : "row", 
                        borderBottom: "1px solid rgba(0,0,0,0.25)",
                        alignItems: "baseline",
                        marginBottom: "1rem",
                        gap: 1
                    }}
                    >
                    <Typography fontWeight={500} gutterBottom>
                        Pressing 'Yes' will{' '}
                        <Box component="span" sx={{ textDecoration: 'underline' }}>
                            permanently
                        </Box>
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 20,  
                            fontWeight: 700,  
                            color: 'error.main',  
                            textTransform: 'uppercase',  
                            letterSpacing: 1,  
                        }}
                        gutterBottom
                    >
                        DELETE
                    </Typography>
                    <Typography fontWeight={500} gutterBottom>
                        the following item:
                    </Typography>
                </Box>
                
                {row && Object.entries(row)
                    ?.filter(([key]) => !excluded.includes(key))
                    ?.map(([key, value]) => (
                    <Box sx={{ display: "flex", flexDirection: "row", whiteSpace: "normal", flexWrap: "wrap" }} key={key}>
                        <Typography fontWeight={500}>
                            {`${key}:`}
                        </Typography>
                        <ValueDisplay value={value} indent={1} />
                    </Box>
                ))}
            </DialogContent>
            <DialogActions>
                <CustomButton size="small" color="primary" variant="outlined" onClick={handleClose}>
                    No
                </CustomButton>
                <CustomButton size="small" color="primary" variant="contained" onClick={handleYesClick}>
                    Yes
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDelete;
