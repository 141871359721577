import { Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Box, InputLabel, Select, OutlinedInput, MenuItem, Checkbox, ListItemText, Autocomplete, TextField, InputAdornment, Button, useTheme } from '@mui/material';
import { GridRowModesModel, GridEventListener, GridRowEditStopReasons, GridRowSelectionModel, GridRowId, DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium';
import ObjectID from 'bson-objectid';
import { evaluate } from 'mathjs';
import React, { 
  useCallback, 
  useEffect, 
  useMemo, 
  useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import RevisionToolbar from './RevisionToolbar';
import useSovOptions from '../../components/hooks/useSovOptions';
import { useCreateDataMutation, useGetDataQuery } from '../../api/apiSliceV2';
import { MenuProps } from '@mui/material/Menu';
import proposalColumns from '../columns';
import SelectMenu from '../../components/forms/SelectMenu';

export const requiredFields:any = {
  specifications: ["grouping_type", "specification", "specification_value", "job_type"],
  'fnd-concrete-pricings': ["jobNumber"],
  'flatwork-sfs': ["plan", "elevation", "option"],
  plps: ["plan", "elevation", "option"],
  'project-notes': ["description", "notes"],
  proposals: ["plan", "elevation", "option"],
  contracts: ["plan", "elevation", "option"],
}



const Revision = ({ 
  step,
  columns, 
  activeStep, 
  completed, 
  handleClose, 
  setCompleted, 
  tagName, 
  job_type, 
  sovIDs, 
  selectedSov,
  setSelectedSov,
  handleSovChange 
}:any) => {
  const {selectedItem, items } = useAppSelector((state:any) => state.api);
  const {project_id, budget_id, proposal_id, contract_id} = useParams();
  const dispatch = useAppDispatch();
  const [rows, setRows] = useState<any>([]);
  const context = step?.route?.split("/")[1];
  const [numRowsToAdd, setNumRowsToAdd] = useState<number>(0);
  const [rowSelectionModel, setRowSelectionModel] = useState<any>([]); 
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const apiRef = useGridApiRef();
  const {isMobile} = useAppSelector((state:any) => state.layout);
  const [selectedValue, setSelectedValue] = useState<string>("");
  const[costTypes, setCostTypes] = useState<any>([]);
  const [increaseType, setIncreaseType] = useState<string>("");
  const [sovID, setSovID] = useState<string[]>([]);
  const [formType, setFormType] = useState<string>("new");
  const [selectedColumn, setSelectedColumn] = useState("");
  const [newValue, setNewValue] = useState("");
  const [price, setPrice] = useState<number>(1);
  const [costDistributionOptions, setCostDistributionOptions] = useState<any>([]);
  const [createData] = useCreateDataMutation();
  const [currentColumns, setCurrentColumns] = useState<any>(columns);
  
  useEffect(() => {
    setCurrentColumns([...proposalColumns?.SovColumns, ...columns]);
  }, [columns]);


  const params = useMemo(() => {
    return {
      url: `/proposals/increase/new`,
      params: {
        project_id,
        job_type_id: selectedItem?.job_type_id?._id,
        budget_reference: selectedItem?.budget_reference?._id
      }
    }
  }, [project_id, selectedItem]);

  const {data: proposalData, isLoading, refetch} = useGetDataQuery(params, {skip: formType === "new"});

  useEffect(() => {
    if(formType === "revision"){
      refetch();
    }
  }, [formType]);

  useEffect(() => {
    if(proposalData?.data && formType === "revision"){
      const responseItems = proposalData?.data?.[project_id || ""]?.items;
      setRows(responseItems?.map((row:any) => {
        return {
          plan: row?.schedule_of_values_id?.plan,
          elevation: row?.schedule_of_values_id?.elevation,
          option: row?.schedule_of_values_id?.option,
          //total_price: row?.schedule_of_values_id?.total_price,
          ...row
        }
      }));
      setNumRowsToAdd(rows?.length)
    } 
  }, [proposalData, formType]);

  useEffect(() => {
    setSelectedValue("")
  },[sovID, increaseType]);

  useEffect(() => {
    if (proposalData?.data) {
      const uniqueCostDistributions = Array.from(new Set(proposalData?.data?.[project_id || ""]?.cost_distributions?.map((item:any) => item.cost_distribution)));
      setCostDistributionOptions(uniqueCostDistributions);
      const uniqueCostTypes = Array.from(new Set(proposalData?.data?.[project_id || ""]?.cost_types?.map((item:any) => item.cost_type)));
      setCostTypes(uniqueCostTypes);
    }
  }, [proposalData]);

  const handleSubmit = async () => {
    try {
      const filtered = rows?.map((row: any) => {
        const { _id, schedule_of_values_id, ...remainingFields } = row;
        return {
          project_id,
          schedule_of_values_id: row?.schedule_of_values_id?._id,
          job_type: selectedItem?.job_type_id?._id,
          ...remainingFields
        };
      })?.filter((row: any) => {
        const fields = requiredFields?.[context] || [];
        return fields.every((field: string) => {
          const value = row[field];
          return value !== undefined && value !== null && value !== '';
        });
      });
      
      let id:string;
      switch(step?.idType){
        case 'project_id':
          id = project_id || "";
          break;
        case 'budget_id':
          id = budget_id || selectedItem?._id;
          break;
        case 'proposal_id':
          id = proposal_id || selectedItem?._id;
          break;
        case 'contract_id':
          console.log(selectedItem)
          id = contract_id || selectedItem?._id;
          break;
        default:
          id = project_id || "";
          break;
      }

       // submit batch
       const submitBatch = async (batch:any) => {
        const response = await createData({
          url: step?.saveUrl(id),
          body: batch,
          tagName
        }).unwrap();
        return response;
      }

      const batchSize = 100;
      for(let i = 0; i < filtered?.length; i += batchSize){
        const batch = filtered?.slice(i, i + batchSize);
        await submitBatch(batch);
      }

      setCompleted({[activeStep]: true});
      
    } catch (error) {
      console.error(error);
    }
  };

  const clearGrid = () => {
    setRows([]);
    setSovID([]);
    setCostDistributionOptions([]);
    setCostTypes([]);
    setIncreaseType("");
    setSelectedValue("");
    setPrice(0);
  }

  const clearRow = () => {
    // clear the rows from the grid
    setRows(rows?.filter((row:any) => !rowSelectionModel.includes(row?._id)));
  }

  const addItem = () => {
    const newRow = {
      _id: new ObjectID(),
    };
    setRowSelectionModel((model:any) => [...model, newRow?._id])
    setRows((currentRows: any) => {
      // Find the index of the selected row
      const selectedIndex = currentRows.findIndex((row: any) => row?._id === rowSelectionModel[0]);
      // If no row is selected, add the new row at the end
      if (selectedIndex === -1) {
        return [...currentRows, newRow];
      }
      // Insert the new row after the selected row
      const updatedRows = [
        ...currentRows.slice(0, selectedIndex + 1),
        newRow,
        ...currentRows.slice(selectedIndex + 1)
      ];
      return updatedRows;
    });
  };

  const addMultipleItems = () => {
    setRowSelectionModel([]);
    for (let i = 0; i < numRowsToAdd; i++) {
      addItem();
    }
  };

  useEffect(() => {
    addMultipleItems();
  },[]);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
 
  const processRowUpdate = useCallback((newRow: any) => {
    setRows((rows:any) => rows.map((row:any) => (row._id === newRow._id) ? newRow : row));
    return newRow;
  }, []);

  const handleRowModesModelChange = (rowModesModel: GridRowModesModel) => {
    setRowModesModel(rowModesModel);
  }

  const handleSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel(rowSelectionModel);
  }

  const updateSelectedRows = () => {
    let updatedRows = [...rows];
  
    rowSelectionModel.forEach((rowId:GridRowId) => {
      const rowToUpdate = updatedRows.find((row) => row._id === rowId);
      if (rowToUpdate) {
        const oldValue = rowToUpdate[selectedColumn];
        if(selectedColumn === "proposal_price"){
          console.log(selectedColumn);

        }
        const updatedValue =  (selectedColumn === "proposal_price") ?
          parseMathExpression(newValue, oldValue)
          :
          newValue;
        const updatedRow = { ...rowToUpdate, [selectedColumn]: updatedValue };
        const processedRow = processRowUpdate(updatedRow);
        updatedRows = updatedRows.map((row) => (row._id === rowId ? processedRow : row));
      }
    });
  
    setRows(updatedRows);
  };

  const parseMathExpression = (value: string, originalValue: number) => {
    try {
      // Replace 'x' with the original value
      const expression = value.replace(/x/g, originalValue.toString());
      // Evaluate the expression
      const result = evaluate(expression);
      if(isNaN(Number(result))) return value;

      return Number(result).toFixed(2);
    } catch (error) {
      console.error("Error parsing expression:", error);
      // If there is an error in parsing, return the original value
      return originalValue;
    }
  };

  const handleColumnChange = (event:any) => {
    setSelectedColumn(event.target.value);
  };
  
  const handleValueChange = (event:any) => {
    setNewValue(event.target.value);
  };

  const handleFormTypeChange = (e:any) => {
    const value = e?.target?.value;
    setFormType(value);
    if(value === "new"){
      clearGrid();
    } 
  }


  const getTakeoffQuantitySum = (id:string) => {
    const searchField = (increaseType === "Cost Distribution") ? "cost_distribution" : "cost_type";

    if (selectedValue) {
      console.log(items?.[project_id || ""]?.[`${searchField}s`])
      const sum = items?.[project_id || ""]?.[`${searchField}s`]
        ?.find((row:any) => {
          console.log(row?.schedule_of_values_id,id)
          return (row?.[searchField] === selectedValue && row?.schedule_of_values_id === id);
        })?.sum;
      console.log(sum)
      return sum; 
    } else {
      return 0;
    }
  };

  const handleCreateIncreaseItem = () => {
    // Iterate over each sovID in the array
    let sovIds:string[] = [];
    sovID.forEach((id:string) => {
      const row = rows?.find((row: any) => row?.schedule_of_values_id === id);
      let item = {
        _id: new ObjectID(),
        plan: row?.plan,
        elevation: row?.elevation,
        option: row?.option,
        option_code: row?.option_code,
        pricing_type: "Increase",
        schedule_of_values_id: id,
        quantity: 1,
      };
      sovIds.push(id);


      let m = new Map();
      rows?.forEach((row:any) => {
        let sum = m.get(row?.schedule_of_values_id) || 0;
        m.set(row?.schedule_of_values_id, sum + row?.proposal_price);
      });

      switch (increaseType) {
        
        case "Percent":
          const s = m?.get(id);
          setRows((rows: any) => [
            {
              ...item,
              proposal_price: Number(evaluate((s * (price / 100)).toFixed(2))),
            }, 
            ...rows
          ]);
          break;
        case "Lump Sum":
          setRows((prevRows: any) => [
            {
              ...item,
              proposal_price: Number(price).toFixed(2),
            },
            ...prevRows,
          ]);
          break;
        default:
          const sum = getTakeoffQuantitySum(id) || 0;
          console.log(sum)
          const result = evaluate(`${sum}*${price}`);
          setRows((currentRows: any) => [
            {
              ...item,
              proposal_price: Number(result).toFixed(2),
            },
            ...currentRows,
          ]);
          break;
      }
    });
    setRowSelectionModel(sovIds);
  };

  const handleApplySov = useCallback(() => {
    const sov = sovIDs?.get(selectedSov);
    if (!sov) return;

    setRows((prevRows: any[]) =>
      prevRows.map((row: any) => {
        if (rowSelectionModel.includes(row?._id)) {
          return {
            ...row,
            schedule_of_values_id: sov?._id,
            plan: sov?.plan,
            elevation: sov?.elevation,
            option: sov?.option,
            option_code: sov?.option_code,
          };
        }
        return row;
      })
    );

    // Force the grid to refresh
    apiRef.current.updateRows(rows);
  }, [rowSelectionModel, selectedSov, sovIDs, rows]);

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sx={{py: 1}}>
        <FormControl>
          <InputLabel id="select-type-label">Select Type</InputLabel>
          <Select
            labelId="select-type-label"
            value={formType}
            onChange={handleFormTypeChange}
            label="Select Type"
            size="small"
            sx={{minWidth: 220}}
          >
            <MenuItem value="new">New</MenuItem>
            <MenuItem value="revision">Revision</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    
      <Grid container sx={{justifyContent: "flex-start", alignItems: "center", padding: "1rem"}} gap={1}>
        {step?.options?.sov && (
            <Grid item xs="auto">
              <SelectMenu options={Array.from(sovIDs.keys())} label="Select Schedule of Values" selectedValue={selectedSov} onChange={(selected: string) => setSelectedSov(selected)} onApply={handleApplySov} />
            </Grid>
          )}
        {formType === "revision" &&
        <Grid container sx={{display: "flex", flexDirection:  isMobile? "column" : "row"}} gap={1}>
          
          
          <Grid item sx={{display: "flex", flexDirection: "row"}}>

            {(increaseType === "Cost Distribution" || increaseType === "Cost Type") &&
            <Autocomplete
              sx={{minWidth: 220, width: "auto" }}
              options={increaseType === "Cost Distribution" ? costDistributionOptions : costTypes || []}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                  <TextField
                    {...params}
                    label={increaseType}
                    variant="outlined"
                    size="small"
                  />
              )}
              value={selectedValue || ""}
              onChange={(event, newValue) => setSelectedValue(newValue || "")}
            /> }
            <TextField
              sx={{width: 220 }}
              label={increaseType === "Percent" ? "Percent (%)" : "$/Unit"}
              type="number"
              size="small"
              value={price}
              onChange={(e:any) => setPrice(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      sx={{ marginLeft: 1 }}
                      variant="text"
                      onClick={handleCreateIncreaseItem}
                    >
                      Create
                    </Button>
                  </InputAdornment>
                ),
              }}
            /> 
          </Grid> 
        </Grid>}
    
        {/* Data Grid */}
        <Grid item xs={12} sx={{height: "auto",}}>
          <DataGridPremium
            sx={{
              fontSize: 14, 
              fontWeight: 500, 
              border: "1px solid rgba(0,0,0,0.25)"
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  created_at: false,
                  created_by: false,
                  updated_at: false,
                  updated_by: false
                },
              },
            }} 
            apiRef={apiRef}
            checkboxSelection
            disableRowSelectionOnClick
            editMode="row"
            getRowId={(row:any) => row?._id}
            density="compact"
            rows={rows || []}
            columns={currentColumns || []}
            loading={isLoading}
            rowHeight={60}
            rowBuffer={10}
            rowModesModel={rowModesModel}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={handleSelectionModelChange}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            experimentalFeatures={{ clipboardPaste: true }}
            slots={{
              toolbar: RevisionToolbar,
            }}
            slotProps={{
              toolbar: {
                setNumRowsToAdd,
                numRowsToAdd,
                addMultipleItems,
                selectedColumn,
                handleColumnChange,
                columns: currentColumns,
                newValue,
                handleValueChange,
                updateSelectedRows,
                clearRow
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{justifyContent: "flex-end"}}>
          <Box sx={{display: "flex", justifyContent: "flex-end"}}>
            {!completed[activeStep] &&
            <Button sx={{marginLeft: 1}} variant="contained" onClick={handleSubmit}  component="label">
                Save items
            </Button>}
            {completed[activeStep] &&
            <Button sx={{marginLeft: 1}} variant="contained" onClick={handleClose}  component="label">
                Close
            </Button>} 
          </Box>
          
        </Grid>
      </Grid>     
    </Grid>
  );
};

export default Revision;
