import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme, size, color }) => ({
  backgroundColor: color || theme.palette.primary.main,
  border: `2px solid ${theme.palette.primary.main}`,
  fontWeight: 800,
  color: theme.palette.common.primary,
  padding: size === 'large' ? '12px 24px' : '8px 16px',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

function CustomButton({ children, onClick, size, color, variant = 'contained', ...props }) {
  return (
    <StyledButton
      size={size}
      color={color}
      variant={variant}
      onClick={onClick}
      {...props}
    >
      {children}
    </StyledButton>
  );
}

export default CustomButton;
