import CustomButton from "../../../components/button/CustomButton";
import { useUpdateDataMutation } from "../../../api/apiSliceV2";
import { ActionType } from "./ActionType";

const FulFillOrder: React.FC<ActionType> = (props:any) => {
    const {row, tagName} = props;
    const [updateData] = useUpdateDataMutation();

    const handleStatusUpdate = async () => {
      try {
        const requestData = {
          ...row,
          order_status: "FulFilled"
        };
        await updateData({
            tagName,
            url: `/warehouse/orders?id=${row?._id}`,
            body: requestData,
        }).unwrap();
      } catch (error:any) {
        console.log(error)
      }
    }
    return (
        <CustomButton size="small" color="success" variant="contained" sx={{color: "white"}} onClick={handleStatusUpdate}>FulFill Order</CustomButton>
    );
}

export default FulFillOrder;