import React, { useEffect, useState } from 'react';
import { Grid, Typography, TextField, useTheme, Stack, Box } from "@mui/material";
import { Autocomplete } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../dialog/ConfirmationDelete';
import { isValid, parseISO } from 'date-fns';
import { formatDateStringWithTime, formatDateString } from "../../../utils/formatDateString";
import CustomButton from '../button/CustomButton';
import { useDeleteDataMutation } from '../../api/apiSliceV2';
import { showSnackbar } from '../snackbar/snackbarSlice';
import { apiRouteMap } from '../../routes/urls';
import { AutocompleteOptions } from '../../../utils/interfaces';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { setSelectedItem } from '../../projects/projectSlice';
import Spinner from '../../spinner/Spinner';

const dateWithTime = [
    'created_at',
    'updated_at'
];
 
interface DetailHeaderProps {
    excludedFields: string[];
    nonEditableFields: string[];
    editable: boolean;
    onJobTypeChange?: any;
    autocompleteOptions: AutocompleteOptions;
    path: string;
    groupingConfig?: any;
    handleSave: (event: MouseEvent) => void;
}   
//console.log
const DetailHeader: React.FC<DetailHeaderProps> = ({
    excludedFields,
    nonEditableFields,
    editable,
    onJobTypeChange,
    autocompleteOptions,
    path,
    groupingConfig,
    handleSave
}) => {
    const { company, division } = useAppSelector((state: any) => state.auth);
    const { selectedItem, activeTag } = useAppSelector((state: any) => state.api);
    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState<any>({});
    const [headerFields, setHeaderFields] = useState<any>({});
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [deleteData] = useDeleteDataMutation();

    useEffect(() => {
        if (selectedItem) {
            setHeaderFields(selectedItem);
            setFormData(selectedItem);
        }
    }, [company, division, selectedItem]);

    const isValidDate = (dateString: string, key: string): boolean => {
        return isValid(parseISO(dateString));
    };  

    const toggleEditMode = () => setEditMode(!editMode);

    const confirmDelete = () => setOpen(!open);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevState: any) => ({ ...prevState, [name]: value }));
    };

    const handleDateChange = (field: string, newValue: dayjs.Dayjs | null) => {
        setFormData((prevState: any) => ({ ...prevState, [field]: newValue }));
    };

    const handleSaveClick = async () => {
        try {
            setIsLoading(true);
           // dispatch(setSelectedItem({}));
            console.log("saving", formData);
            handleSave(formData);
            const updatedItem = { ...selectedItem, ...formData };
            setFormData(updatedItem);
            setHeaderFields({
                company_number: company?.company_number,
                division_number: division?.division_number,
                ...updatedItem,
            });
            toggleEditMode();
        } catch (error: any) {
            const errorMessage = error?.data?.message || 'Failed to update data';
            dispatch(showSnackbar({ message: `Error: ${errorMessage}`, type: 'error' }));
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            // Parse the activeTag
            const lastColonIndex = activeTag.lastIndexOf(':');
            const parsedTag = activeTag.substring(0, lastColonIndex);
            // Delete the item
            await deleteData({
                tagName: parsedTag,
                url: apiRouteMap?.get(path)?.delete(selectedItem?._id)
            }).unwrap();
            navigate(-1);
        } catch (error: any) {
            console.log("Error deleting data: ", error)
        }
    };

    const roles = ['estimator', 'supervisor', 'foreman', 'jc_accountant'];

    const renderNestedObject = (obj: any) => {
        return (
            <Stack direction="column" spacing={0}>
                {Object.entries(obj).filter(([k]: any) => k !== '_id').map(([k, v]: any) => (
                    <Stack key={k} direction="row" spacing={1}>
                        {k === 'customer_id' ? (
                            <Typography color="text.primary" variant="body2" sx={{ fontWeight: 600 }}>
                                {`Customer: ${v?.customer_legal_name || ''}`}
                            </Typography>
                        ) : (
                            <>
                                <Typography color="text.primary" variant="body2" sx={{ fontWeight: 600 }}>
                                    {`${k}: `}
                                </Typography>
                                {typeof v === 'object' && v !== null ? (
                                    <Box>
                                        {renderNestedObject(v)}
                                    </Box>
                                ) : (
                                    <Typography variant="body2" sx={{ fontWeight: 600}}>
                                        {`${v}`}
                                    </Typography>
                                )}
                            </>
                        )}
                    </Stack>
                ))}
            </Stack>
        );
    };

    const renderFieldValue = (key: string, value: any) => {
        if (isValidDate(value, key)) {
            return !dateWithTime?.includes(key) ? formatDateString(value) : formatDateStringWithTime(value);
        } else if (typeof value === 'number') {
            return value.toFixed(3);
        } else if (Array.isArray(value)) {
            return value.join(', ');
        } else if (typeof value === 'object' && value !== null) {
            if (key === 'project_id') {
                return renderNestedObject(value);
            } else if (key === 'job_type_id') {
                return value['job_type_description'] || '';
            } else if (roles.includes(key)) {
                return value['employee_name'] || '';
            }else if( key === 'customer_id') {
                return value?.customer_legal_name || '';
            }else if(key === "budget_reference") {
                return `${value?.budget_rev_no} - ${value?.budget_name}` || '';
            }else if (key === 'proposal_reference') {
                return `${value?.proposal_rev_no} - ${value?.proposal_name}` || '';
            }
            const config = groupingConfig.find((item: any) => item.idField === key);
            if (config && value[config.idField]) {
                return `${config.key}: ${value[config.idField]}`;
            }
            return JSON.stringify(value); // Fallback for other objects
        }
        return String(value);
    };

    const renderField = (key: string, value: any) => {
        const isEditable = editable && !nonEditableFields.includes(key);

        if (!editMode || !isEditable) {
            return (
                <Stack 
                    direction={"row"} 
                    justifyContent="start" 
                    alignItems="start"
                >
                    {key !== 'project_id' && 
                        <Typography variant="body2" sx={{fontWeight: 600, mr: 1}}>
                            {`${key?.split("_")[0]} ${key?.split("_")[1] || ''}: `}
                        </Typography>
                    }
                    <Typography variant="body2" sx={{textWrap: "wrap", color: theme.palette.primary.main, fontWeight: 600 }}>
                        {renderFieldValue(key, value)}
                    </Typography>
                </Stack>
            );
        } else {
            if (isValidDate(value, key)) {
                return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{width: "100%" }}
                            label={key}
                            value={dayjs(formData?.[key])}
                            onChange={(newValue) => handleDateChange(key, newValue)}
                            disabled={!isEditable}
                        />
                    </LocalizationProvider>
                );
            } else if (autocompleteOptions?.[key]) {
                return (
                    <Autocomplete
                        freeSolo
                        fullWidth
                        multiple={Array.isArray(value)}
                        options={autocompleteOptions?.[key]?.options || []}
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            if (typeof option === 'object' && option !== null) {
                                const relevantKey = Object.keys(option).find(k => 
                                    k.toLowerCase().includes('name') || k.toLowerCase().includes('description')
                                );
                                if (relevantKey) {
                                    return option[relevantKey];
                                }
                            }
                            return JSON.stringify(option);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={key}
                                variant="standard"
                                error={false}
                                helperText=""
                                fullWidth
                            />
                        )}
                        value={formData[key] || selectedItem[key] || ""}
                        onChange={(_, newValue) => {
                            if ((key === 'job_type' || key === 'job_type_id') && onJobTypeChange) {
                                onJobTypeChange(newValue);
                            }
                            setFormData((prevState: any) => ({
                                ...prevState,
                                [key]: newValue || "",
                            }));
                        }}
                        isOptionEqualToValue={(option, value) => 
                            option === value || option._id === value._id
                        }
                        disabled={!isEditable}
                    />
                );
            } else {
                return (
                    <TextField
                        label={key}
                        name={key}
                        value={formData?.[key] || ""}
                        onChange={handleChange}
                        variant="standard"
                        fullWidth
                        type={typeof value === 'number' ? 'number' : 'text'}
                        disabled={!isEditable}
                    />
                );
            }
        }
    };

    const renderGroupedFields = () => {
        const groupedFields: Record<string, any> = {};

        if(!groupingConfig?.length) return (
            // Print all fields across the top of the page
            <Grid container spacing={editMode ? 2 : 0} sx={{width: "100%", padding: "1rem"}}>
                {Object.entries(headerFields)?.filter(([key]) => !excludedFields?.includes(key))?.map(([key, value]) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
                        {renderField(key, value)}
                    </Grid>
                ))}
            </Grid>
        )

        groupingConfig.forEach(({ key, idField }: { key: string, idField: string }) => {
            if (!groupedFields[key]) {
                groupedFields[key] = {};
            }
            if (headerFields[idField] !== undefined && !excludedFields.includes(idField)) {
                groupedFields[key][idField] = headerFields[idField];
            }
        });



        if(!editMode && Object.keys(groupedFields).length > 0){
            return Object.entries(groupedFields).map(([groupName, fields]) => (
                <Grid item xs='auto' sx={{minWidth: "220px", mt: 1}} key={groupName}>
                    <Stack>
                        <Typography variant="body1" sx={{textDecoration: "underline", fontWeight: 600, fontSize: "1rem" }}>
                            {groupName}
                        </Typography>
                        {Object.entries(fields).map(([key, value]) => (
                            <Stack direction="row" justifyContent="start" alignItems="start" key={key}>
                                {renderField(key, value)}
                            </Stack>
                        ))}
                    </Stack>
                </Grid>
            ));
        }

        return Object.entries(groupedFields).map(([groupName, fields]) => (
            <Grid item xs={12} key={groupName}>
                <Typography variant="h6" sx={{textDecoration: "underline", fontWeight: 600, mb: 2}}>
                    {groupName}
                </Typography>
                <Grid container spacing={2}>
                    {Object.entries(fields).map(([key, value]) => {
                        const isEditable = !nonEditableFields.includes(key);
                        return (
                            <Grid item xs={editMode && isEditable ? 6 : 12} sm={editMode && isEditable ? 4 : 6} md={3} key={key}>
                                <Stack 
                                    direction={editMode && isEditable ? "column" : "row"} 
                                    justifyContent="start" 
                                    alignItems="start" 
                                    spacing={1}
                                >
                                    {renderField(key, value)}
                                </Stack>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        ));
    };

    if (isLoading) return <Spinner />;

    return (
        <Grid container sx={{width: "100%", height: "auto", textTransform: "uppercase"}} gap={2}>
            {selectedItem && 
            <Grid container spacing={2}>
                {renderGroupedFields()}
            </Grid>}
            {/* Edit/Save/Delete Buttons */}
            <Grid container sx={{ display: "flex", width: "100%"}}>
                <Grid item xs={6} sx={{ display: "flex" }}>
                    {editable && (
                        <Grid item>
                            <CustomButton color="primary" variant="outlined" size="small" onClick={toggleEditMode}>
                                {editMode ? 'Cancel' : 'Edit'}
                            </CustomButton>
                        </Grid>
                    )}
                    {editMode && (
                        <Grid item sx={{ marginLeft: 1 }}>
                            <CustomButton color="primary" variant="contained" size="small" onClick={handleSaveClick}>
                                Save
                            </CustomButton>
                        </Grid>
                    )}
                </Grid>
                {editMode && (
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Grid item>
                            <CustomButton color='error' variant="contained" size="small" onClick={confirmDelete}>
                                Delete
                            </CustomButton>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        
            <ConfirmationDialog
                row={headerFields}
                open={open}
                setOpen={setOpen}
                handleDelete={handleDelete}
            />
        </Grid>
    );
};

export default DetailHeader;
