import axios from "axios";

const AxiosSetup = () => {
  //const navigate = useNavigate();

  const appAPI = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true // Sets cookies
});

// Add a request interceptor
appAPI.interceptors.request.use(function (config) {
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

// Add a response interceptor
appAPI.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    return Promise.reject(error);
  });

  return appAPI;

}

const appAPI = AxiosSetup();

export default appAPI;