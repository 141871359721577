import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid } from "@mui/material";
import { useAppSelector } from '../../../app/hooks';
import { useParams } from 'react-router-dom';
import { DataGridPremium, GridCellParams, GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { plpItemColumns } from '../columns/columns';
import styles from '../detail.module.css';
import SovSelection from './SovSelection';
import WarehouseSearchToolbar from '../../warehouse/warehouse-toolbar/WarehouseSearchToolbar';
import { useCreateDataMutation, useGetDataQuery } from '../../api/apiSliceV2';
import { apiRouteMap } from '../../routes/urls';

const CreateOrder = ({ step, activeStep, completed, handleClose, jobType, tagName}:any) => {
  const {message, isLoading, formErrors, isError} = useAppSelector((state:any) => state.api);
  const {project_id, start_id} = useParams();
  const [rowSelectionModel, setRowSelectionModel] = useState<any>([]); 
  const [rows, setRows] = useState<any>([]);
  const [sovIDs, setSovIDs] = useState<string[]>([]);
  const apiRef = useGridApiRef();
  const [createData] = useCreateDataMutation();
  const {data: response} = useGetDataQuery({url: apiRouteMap.get("/prebuilt-orders/items").getDefaultItems()});
  
  useEffect(() => {
   if(response?.data){
      setRows(response?.data);
    }  
  }, [response]);

  const handleSubmit = async () => {
    try {

      let data = {
        schedule_of_values_ids: sovIDs,
      }
      
      const url = step?.idType === "project_id" ? step?.saveUrl(project_id) : step?.saveUrl(start_id);
      const response = await createData({
        //tagName,
        body: data,
        url
      }).unwrap();
      if(response.data){
        //Create default selected items
        const url = (step?.orderType === "pre-built order") ?  
          `/prebuilt-orders/items?prebuilt_order_id=${response?.data?._id}`
        :
          `/warehouse/orders/items?order_id=${response?.data?.warehouseOrder?._id}`;
        const formattedItems = rows
          ?.filter((item: any) => rowSelectionModel.includes(item?.id))  // Filter out items not in the selection model
          ?.map((item: any) => {
            if(step?.orderType === "pre-built order"){
              return {
                cost_type: item.cost_type,
                part_number: item.part_number,
                part_description: item.part_description,
                prebuilt_quantity: item.prebuilt_quantity || 0,
                unit_of_measure: item.unit_of_measure,
                segment_one: item?.segment_one,
                segment_two: item?.segment_two,
              }
            }
            return {
              cost_type: item.cost_type,
              part_number: item.part_number,
              part_description: item.part_description,
              order_quantity: item.prebuilt_quantity || 0 ,  
              unit_of_measure: item.unit_of_measure,
              segment_one: item?.segment_one,
              segment_two: item?.segment_two,
            }  
        });

        await createData({
          tagName,
          body: formattedItems,
          url
        });
        
      } 
      if(!isError && !isLoading) handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSovIdChange = (event:any) => {
    const values = event.target.value;
    setSovIDs(values);
  };

  const handleSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel(rowSelectionModel);
  }

  const processRowUpdate = useCallback((newRow: any) => {
    const id = newRow?._id || newRow?.id || newRow?.item_id;
    apiRef.current.selectRow(id);
    setRows((rows:any) => rows.map((row:any) => (row.id === newRow.id) ? newRow : row));
    return newRow;
  }, [apiRef]);

  return (
    <Grid container spacing={2} justifyContent="center" sx={{justifyContent: "flex-start", alignItems: "center", height: "800px", width: "100%"}} >
      <Grid item xs={12}>
        <SovSelection  handleSovIdChange={handleSovIdChange} jobType={jobType} /> 
      </Grid>
      {/* Data Grid */}
      <Grid item xs={12} sx={{height: "800px"}}>
        <DataGridPremium
          sx={{
            fontSize: 14, 
            fontWeight: 500, 
            border: "1px solid rgba(0,0,0,0.25)",
            height: "100%"
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                created_at: false,
                created_by: false,
                updated_at: false,
                updated_by: false
              },
            },
          }} 
          apiRef={apiRef}
          checkboxSelection
          disableRowSelectionOnClick
          editMode="row"
          getRowId={(row:any) => row?._id || row?.id}
          density="compact"
          rows={rows || []}
          columns={plpItemColumns || []}
          loading={isLoading}
          rowHeight={40}
          rowBuffer={10}
          getCellClassName={(params: GridCellParams<any, any, number>) => {
            return params.isEditable ? `${styles.editableCell}` : '';
          }}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={handleSelectionModelChange}
          processRowUpdate={processRowUpdate}
          experimentalFeatures={{ clipboardPaste: true }}
          slots={{
            toolbar: WarehouseSearchToolbar
          }}
          slotProps={{
            toolbar: {
              orderType: 'pre-built-order',
              order_id: "",
            }
          }}
        />
      </Grid>
    
      <Grid item xs={12} sx={{justifyContent: "flex-end"}}>
        <Box sx={{display: "flex", justifyContent: "flex-end", mb: 2}}>
          {!completed[activeStep] &&
          <Button sx={{marginLeft: 1}} variant="contained" onClick={handleSubmit}  component="label">
            Create Order
          </Button>}
          {completed[activeStep] &&
          <Button sx={{marginLeft: 1}} variant="contained" onClick={handleClose}  component="label">
            Close
          </Button>} 
        </Box>
      </Grid>

      {(isError) && 
      <Grid container justifyContent="flex-start" alignItems="center" sx={{mb: 2}} spacing={1}>
        {formErrors ? (
          <Grid item sm={12} xs={12} sx={{fontSize: 12, color: "red"}}>
            <ul>
              {Object.entries(formErrors).map(([key, value]:any) => (
                  <li key={key}>{value}</li>
              ))}
            </ul>
          </Grid>
        ) : (
          <Grid item sm={12} xs={12} sx={{fontSize: 12, color: "red"}}>
            {message}
          </Grid>
        ) }
      </Grid>}
      
    </Grid>
  );
};

export default CreateOrder;
