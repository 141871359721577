import React from 'react';
import { CircularProgress } from '@mui/material';
import CustomButton from '../button/CustomButton';
import { useParams } from 'react-router-dom';
import { useUploadDataMutation } from '../../api/apiSliceV2';

const FileUploadButton: React.FC = (props: any) => {
    const { _id, startId, tagName } = props;
    const { project_id, budget_id, proposal_id, contract_id, start_id } = useParams();
    const [uploadData, { isLoading }] = useUploadDataMutation();

    const handleButtonClick = () => {
        document.getElementById('file-input')?.click();
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            await uploadFile(file);
        }
    };

    const uploadFile = async (file: File) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            let idType = "";
            if (_id) {
                idType = "Item Document";
            } else if (start_id) {
                idType = "Start Document";
            } else if (budget_id) {
                idType = "Budget Document";
            } else if (proposal_id) {
                idType = "Proposal Document";
            } else if (contract_id) {
                idType = "Contract Document";
            }

            if (idType) formData.append('idType', idType);
            if (project_id) formData.append('project_id', project_id);
            if (budget_id) formData.append('budget_id', budget_id);
            if (proposal_id) formData.append('proposal_id', proposal_id);
            if (contract_id) formData.append('contract_id', contract_id);
            if (startId || start_id) formData.append('start_id', startId || start_id);
            if (_id) formData.append('_id', _id);

            await uploadData({
                tagName,
                body: formData,
                url: `/documents`
            }).unwrap();
        } catch (error: any) {
            console.error('File upload failed:', error);
        }
    };

    return (
        <>
            <input
                type="file"
                id="file-input"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <CustomButton
                size="small"
                variant="contained"
                color="primary"
                onClick={handleButtonClick}
                disabled={isLoading}
            >
                {isLoading ? <CircularProgress size={24} sx={{width: '24px', height: '24px'}} thickness={7.2} disableShrink color="primary" /> : 'Select File'}
            </CustomButton>
        </>
    );
};

export default FileUploadButton;
