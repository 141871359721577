import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  message: '',
  type: 'info' // 'success', 'error', 'warning', 'info'
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackbar: (state, action) => {
      state.isOpen = true;
      state.message = action.payload.message;
      state.type = action.payload.type || 'info';
    },
    hideSnackbar: (state) => {
      state.isOpen = false;
      state.message = '';
      state.type = 'info';
    }
  }
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
