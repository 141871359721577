import CustomButton from "../../../components/button/CustomButton";
import { useUpdateDataMutation } from "../../../api/apiSliceV2";
    
const RejectOrder = (props:any) => {
    const {row, tagName} = props;
    const [updateData] = useUpdateDataMutation();

    const handleStatusUpdate = async () => {
        try {
            const requestData = {
                ...row,
                order_status: "REJECTED"
            };
            await updateData({
                tagName,
                url: `/warehouse/orders?id=${row?._id}`,
                body: requestData,
            }).unwrap();

        } catch (error:any) {
            console.log(error)
        }
    }
    return (
        <CustomButton size="small" color="error" variant="contained" sx={{color: "white"}} onClick={handleStatusUpdate}>Reject Order</CustomButton>
    )
}

export default RejectOrder;