
import { IconButton, Link, MenuItem, Select, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { useDeleteDataMutation } from '../../api/apiSliceV2';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';

const useLotColumns = (): GridColDef[] => {
  const location = useLocation();
  const {project_id} = useParams();
  const {selectedProject, activeTag} = useAppSelector((state:any) => state.api);
  const [deleteData] = useDeleteDataMutation();

  // remove lot from start
  const handleRemoveLot = async (start_id:string, lot_id:string) => {
    try {
      await deleteData({
        tagName: [activeTag, activeTag?.includes("/Lots") ? activeTag : `${activeTag}/lots`],
        url: `/starts/items/lots?start_id=${start_id}&lot_id=${lot_id}`
      }).unwrap();
    } catch (error) {
      console.log("error deleting data")
    }
  }

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'lot_number',
      headerName: 'Lot Number',
      width: 80,
      headerAlign: "left",
      align: "left",
      type: 'string',
      sortable: true,
      editable: true
    },
    {
      field: 'subjob_and_job_type',
      headerName: 'Subjob & Job Type',
      headerAlign: "left",
      align: "left",
      type: 'string',
      sortable: true,
      width: 220,
      editable: false, 
      renderCell: (params: any) => {
        const { row } = params;
        const startDetails = row.startDetails || []; // Access the startDetails array
      
        // Map over startDetails and return formatted subjob numbers with job type descriptions and remove button
        const subjobNumbers = startDetails
          .filter((start: any) => start?.subjob_number)
          .map((start: any) => {
            const linkPath = `/projects/${project_id}/starts/${start._id}`;
            return (
              <Stack key={start._id} direction="row" alignItems="start" spacing={1}>
                <Stack direction="column">
                  {/* Link to the subjob number */}
                  <Link href={linkPath} style={{ textDecoration: 'none', color: 'blue' }}>
                    {`${selectedProject?.job_number ? selectedProject.job_number + ' - ' : ''}${start.subjob_number}`}
                  </Link>
                  {/* Display the job type description */}
                  <Typography variant="body2" sx={{fontWeight: 500}}>
                    {start?.job_type?.job_type_description || 'N/A'}
                  </Typography>
                </Stack>
                {/* IconButton to remove the lot */}
                <IconButton
                  aria-label="remove"
                  size="small"
                  onClick={() => handleRemoveLot(start?._id, row?._id)}
                >
                  <ClearSharpIcon fontSize='small' />
                </IconButton>
              </Stack>
            );
          });
      
        // Join all start items with a comma and space between them
        return (
          <Stack direction="row" spacing={1}>
            {subjobNumbers.length > 0 ? subjobNumbers?.map((subjob:any) => {
              return <span>{subjob}</span>
            }) : <span>{'N/A'}</span>}
          </Stack>
        );
      },
      
    }, 
    {
      field: 'address',
      headerName: 'Address',
      sortable: true,
      headerAlign: "left",
      align: "left",
      type: 'string',
      width: 220,
      editable: true,
    },
    {
      field: 'garage_orientation',
      headerName: 'Orientation',
      headerAlign: "left",
      align: "left",
      type: 'string',
      sortable: true,
      width: 80,
      editable: true,
      renderEditCell: (params) => {
        return (
          <Select value={params.value} onChange={(e:any) => params.api.setEditCellValue({ id: params.id, field: params.field, value: e.target.value }, e)}>
            <MenuItem value="R">R</MenuItem>
            <MenuItem value="L">L</MenuItem>
          </Select>
        )
      
      },
    }, 
    {
      field: 'customer_plan',
      headerName: 'Customer Plan',
      headerAlign: "left",
      align: "left",
      type: 'string',
      sortable: true,
      width: 120,
      editable: true,
    }, 
    {
      field: 'customer_elevation',
      headerName: 'Customer Elevation',
      headerAlign: "left",
      align: "left",
      type: 'string',
      sortable: true,
      width: 60,
      editable: true,
    }, 
    {
      field: 'customer_release',
      headerName: 'Customer Release',
      headerAlign: "left",
      align: "left",
      type: 'string',
      sortable: true,
      width: 80,
      editable: true,
    },
    {
      field: 'finished_floor',
      headerName: 'FF',
      headerAlign: "left",
      align: "left",
      type: 'number',
      editable: true,
      width: 60,
    },
    {
      field: 'lot_pad_height',
      headerName: 'PAD',
      headerAlign: "left",
      align: "left",
      type: 'number',
      editable: true,
      width: 60,
    },
    {
      field: 'finished_grade',
      headerName: 'FG',
      headerAlign: "left",
      align: "left",
      type: 'number',
      editable: true,
      width: 60,
    },
    {
      field: 'garage_finished_floor',
      headerName: 'GFF',
      headerAlign: "left",
      align: "left",
      type: 'number',
      editable: true,
      width: 60,
    },
    {
      field: 'garage_lip',
      headerName: 'GL',
      headerAlign: "left",
      align: "left",
      type: 'number',
      editable: true,
      width: 60,
    },    
    {
      field: 'assessors_parcel_number',
      headerName: 'APN',
      headerAlign: "left",
      align: "left",
      type: 'string',
      editable: true,
      width: 120,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      headerAlign: "left",
      align: "left",
      type: 'date',
      width: 160,
      filterable: true,
      editable: false,
      valueGetter: (params: any) => params.value ? new Date(params.value) : undefined,
      valueFormatter: (params: any) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      },
    },
    {
      field: 'created_by',
      headerName: 'Created By',
      headerAlign: "left",
      align: "left",
      type: 'string',
      width: 160,
      filterable: true,
      editable: false,
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      headerAlign: "left",
      align: "left",
      type: 'date',
      width: 160,
      filterable: true,
      editable: false,
      valueGetter: (params: any) => params.value ? new Date(params.value) : undefined,
      valueFormatter: (params: any) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      },
    },
    {
      field: 'updated_by',
      headerName: 'Updated By',
      headerAlign: "left",
      align: "left",
      type: 'string',
      width: 160,
      filterable: true,
      editable: false,
    }
  ], [location, selectedProject, activeTag]);

  return columns;
};

export default {useLotColumns};