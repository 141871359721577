import React from 'react';
import useStartConfig from './useStartConfig';
import DetailView from "../components/detail-view/Detail";

const Starts: React.FC = () => {
  const {tabs}: any = useStartConfig();
  return <DetailView tabs={tabs} />
}
  
export default Starts;
