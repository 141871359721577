import { Box, Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import ConfirmRemoveAll from '../../dialog/ConfirmRemoveAll';
import DropdownMenuButton from '../../button/DropdownButton';
import DynamicStepper from '../../stepper/Stepper';
import { GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-premium';
import ExpandAllButton from '../../button/ExpandAllButton';

interface CustomGridToolbarProps {
    context?: any;
    exportItems?: boolean;
    handleDeleteAll?: () => void;
    options: {
        showHandleDeleteAll: boolean;
        showQuickFilter: boolean;
        showPrintOptions: boolean;
        showExpandAll: boolean;
    };
    expandedRowIds?: string[];
    rows?: any[];
    setExpandedRowIds?: (expandedRowIds: string[]) => void;
}

const CustomGridToolbar = (props:CustomGridToolbarProps) => {
    const { context, exportItems, handleDeleteAll, options} = props;
    const [open, setOpen] = useState<boolean>(false);

    return(
        <Box>
            <GridToolbarContainer sx={{fontWeight: 600}}>
                {context?.steps?.length > 0 && 
                    <DynamicStepper
                        context={context}
                        {...props}
                    />
                }
                {
                    options?.showExpandAll &&
                    <ExpandAllButton {...props} expandedRowIds={props.expandedRowIds} rows={props.rows} setExpandedRowIds={props.setExpandedRowIds} />
                }
                {options?.showHandleDeleteAll &&
                    <Button  sx={{ marginLeft: 0.5 }} color="error" variant="text" onClick={() => setOpen((open:boolean) => !open)}>
                    Delete All
                    </Button>
                }
                {options?.showQuickFilter && <GridToolbarFilterButton sx={{fontWeight: 600}} />}
                {/* <GridToolbarColumnsButton sx={{fontWeight: 600}} /> */}
                {options?.showPrintOptions && <GridToolbarExport sx={{fontWeight: 600}} />}

                {exportItems && 
                <Grid item xs={12}>
                    <DropdownMenuButton {...props}/>
                </Grid>}
            </GridToolbarContainer>
            <ConfirmRemoveAll
                open={open}
                setOpen={setOpen}
                handleDelete={handleDeleteAll}
            />
        </Box> 
    )
}

export default CustomGridToolbar;
