import React, { useEffect, useState } from "react";
import { alpha, Box, Dialog, DialogContent, Grid, IconButton, Step, StepLabel, Stepper, Typography, useTheme } from "@mui/material";
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useNavigate } from "react-router-dom";
import { reset, setSelectedItem } from "./stepperSlice";
import CustomButton from "../button/CustomButton";

const DynamicStepper = (props:any) => {
    const { context, tagName } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // Redux state
    const { selectedItem } = useAppSelector((state: any) => state.stepper);

    // Local state
    const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
    const [activeStep, setActiveStep] = useState(0);
    const [open, setOpen] = useState(false);

    // Derived values
    const stepLength = context?.steps?.length;
    const columns = context?.steps?.[activeStep]?.getColumns() || [];

    const theme = useTheme();

    useEffect(() => {
        if (completed[activeStep]) {
            if (context?.steps?.[activeStep]?.redirect) {
                // Redirect to the next step after a delay
                const redirectUrl = (selectedItem?.project_id) ? 
                    context?.steps?.[activeStep]?.redirect(selectedItem?.project_id, selectedItem?._id || "") 
                : 
                    context?.steps?.[activeStep]?.redirect(selectedItem?._id || "");
                setTimeout(() => navigate(redirectUrl), 500);
            } else if (isLastStep()) {
                // Close the stepper after a delay when the last step is completed
                setTimeout(() => {
                    handleClose();
                }, 500);
            } else {
                // Move to the next step only if it's not the last step, with a 2-second delay
                setTimeout(() => {
                    setActiveStep(prevStep => prevStep + 1);
                }, 1000);
            }
        }
    }, [completed, activeStep, selectedItem]);

    // Check if the step is optional
    const isStepOptional = (stepIndex:number) => {
        return context?.steps?.[stepIndex]?.isOptional;
    }

    // Check if the step is the last step
    const isLastStep = () => {
        return context?.steps?.length -1 === activeStep;
    }

    // Handle step navigation
    const handleStep = (step: number) => () => {
        if (step > stepLength - 1 || step < 0) return; 
    
        const currentStepCompleted = completed[activeStep];
        const currentStepOptional = isStepOptional(activeStep);
 
        if (step <= activeStep || currentStepOptional || currentStepCompleted) {
            setActiveStep(step); 
        } else if (isStepOptional(step) && Object.keys(completed).length >= step && completed[step - 1]) {
            setActiveStep(step);
        } else {
            console.log("Cannot navigate to an uncompleted, non-optional step ahead or skip current mandatory step.");
        }
    };

    // Handle opening the stepper
    const handleOpen = () => {
        setOpen(true);
    }

    // Handle closing the stepper
    const handleClose = async () => {
        setOpen(false);
        await resetState();
        dispatch(setSelectedItem({}));    
    }

    // Reset the stepper state
    const resetState = async () => {
        setActiveStep(0);
        setCompleted({});
        dispatch(reset());
    }

    return (
        <>
            <CustomButton variant="contained" size="small" color="primary" onClick={handleOpen}>
                <Typography sx={{fontWeight: 800, color: "white"}} variant="body2">
                    {` ${context?.steps?.[activeStep]?.label}`}
                </Typography>
            </CustomButton>
            <Dialog 
                fullWidth 
                maxWidth="lg" 
                open={open} 
                onClose={() => setOpen(false)}
                slotProps={{
                    backdrop: {
                        style: {
                            backgroundColor: alpha(theme.palette.primary.contrastText, 0.25), // Adjust opacity as needed
                        },
                    },
                }}
            >
                <DialogContent>
                    <Grid container justifyContent="flex-start" alignItems="flex-end" sx={{height: "auto", padding: "1rem 0 1rem 0"}}>
                        <Grid item xs={11.5} sx={{ order: 1}}>
                            <Stepper activeStep={activeStep}>
                                {context?.steps && context?.steps?.map((step:any, index:number) => {
                                    const labelProps: {
                                        optional?: React.ReactNode;
                                    } = {};
                                    if(isStepOptional(index)){
                                        labelProps.optional = (
                                            <Typography variant="caption">Optional</Typography>
                                        );
                                    }
                                    return (
                                        <Step key={step?.label} onClick={handleStep(index)} completed={completed[index]}>
                                            <StepLabel {...labelProps}>{step?.label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Grid>
                        <Grid item xs={0.5} sx={{display: "flex", justifyContent: "flex-end", order: 2}}>
                            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                                <IconButton
                                    aria-label="remove"
                                    onClick={() => handleClose()}
                                    size="small"
                                    sx={{opacity: 0.9}}
                                >
                                    <ClearSharpIcon fontSize="medium" />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container sx={{justifyContent: "center", alignItems: "center", width: "100%" }}>
                        {context?.steps && context?.steps?.map((step:any, index:number) => {
                            if(activeStep === index){
                                return (
                                    <step.Component
                                        key={index}
                                        context={context}
                                        step={step}
                                        tagName={tagName}
                                        columns={columns}
                                        open={open}
                                        isLastStep={isLastStep}
                                        handleClose={handleClose}
                                        completed={completed}
                                        activeStep={activeStep}
                                        setCompleted={setCompleted}  
                                    />
                                );
                            }
                        })}                      
                    </Grid>                 
                </DialogContent>
            </Dialog>
        </>
    );
}

export default DynamicStepper;
