import { NavigateFunction } from "react-router-dom";

let navigate:any;

export const setNavigator = (nav:NavigateFunction) => {
    navigate = nav;
}

export const navigateTo = (path:string) => {
    if(navigate){
        navigate(path);
    }
}