import axios from "axios";

const cemexAPI = axios.create({
    baseURL: 'https://localhost:7777/api/v1',
    timeout: 5000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
});

// Add a request interceptor
cemexAPI.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});

// Add a response interceptor
cemexAPI.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    return Promise.reject(error);
  });

export default cemexAPI;