import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Layout from './features/layout/Layout';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAppDispatch, useAppSelector} from './app/hooks';
import { currentAuthenticatedUser, reset } from './features/auth/authSlice';
import Spinner from './features/spinner/Spinner';

function App() {
  const {themeMode, themeConfig} = useAppSelector((state:any) => state.layout);
  const { isAuthenticated, userDetails, isLoading } = useAppSelector((state:any) => state.auth);
  const [theme, setTheme] = useState<any>({});
  const [isInitialized, setIsInitialized] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const checkUser = async () => {
      try {
        const result = await dispatch(currentAuthenticatedUser()).unwrap();
        setIsInitialized(true);
      } catch (error) {
        console.error('Authentication error:', error);
        dispatch(reset());
        setIsInitialized(true);
      }
    };
    checkUser();
  }, [dispatch]);

  useEffect(() => {
    if(themeConfig){
      setTheme(
        createTheme({
          ...themeConfig,
          palette: {
            ...themeConfig.palette,
            mode: themeMode,
          },
      }))
    }   
  },[themeConfig, themeMode]);

  // Show loading state while checking authentication and loading user details
  if (!isInitialized || isLoading || (isAuthenticated && !userDetails)) {
    return <Spinner />;
  }

  return (
    <Router>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme/>
          <Layout />
        </ThemeProvider>
      </LocalizationProvider>
    </Router>
  );
}

export default App;
