import { Dialog, DialogTitle, DialogContent, Box, Typography, DialogActions } from "@mui/material";
import { useAppSelector } from "../../../app/hooks";
import CustomButton from "../button/CustomButton";

const ConfirmRemoveAll = (props: any) => {
    const { open, handleDelete, setOpen } = props;
    const { isMobile } = useAppSelector((state: any) => state.layout);

    const handleYesClick = () => {
        handleDelete();
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose} // Close the dialog without deleting
        >
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent dividers>
                <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "baseline" }}>
                    <Typography gutterBottom>
                        Pressing 'Yes' will
                    </Typography>
                    <Typography sx={{ margin: 0.5, textDecoration: "underline" }} gutterBottom>permanently</Typography>
                    <Typography sx={{ margin: 0.5, fontSize: 18, fontWeight: 600 }} gutterBottom color="error">
                        DELETE
                    </Typography>
                    <Typography gutterBottom>
                        all documents in this collection!
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <CustomButton color="primary" size="small" variant="outlined" onClick={handleClose}>
                    No
                </CustomButton>
                <CustomButton color="primary" size="small" variant="contained" onClick={handleYesClick}>
                    Yes
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmRemoveAll;
