import { useMemo } from 'react';
import { createTag, parseTag, TagTypes, TagType } from '../../api/tagTypes';
import { useParams } from 'react-router-dom';

interface ProjectTagProps {
  company?: { _id: string };
  division?: { _id: string };
  context?: { tagType: string };
}

export const useTagName = ({
  company,
  division,
  context,
}: ProjectTagProps) => {
  const { project_id, start_id, budget_id, proposal_id, contract_id } = useParams();
  
  const id = useMemo(() => {
    if (start_id) return start_id;
    if (budget_id) return budget_id;
    if (proposal_id) return proposal_id;
    if (contract_id) return contract_id;
    return "";
  }, [start_id, budget_id, proposal_id, contract_id]);

  const projectTag = useMemo(() => createTag(
    company?._id ? { type: TagTypes.COMPANIES, id: company._id } : undefined,
    division?._id ? { type: TagTypes.DIVISIONS, id: division._id } : undefined,
    project_id ? { type: TagTypes.PROJECTS, id: project_id } : undefined,
  ), [company?._id, division?._id, project_id]);

  const parsedProjectTag = useMemo(() => parseTag(projectTag), [projectTag]);

  const fullTagName = useMemo(() => {
    if (!context?.tagType) {
      return projectTag;
    }
    return createTag(
      ...parsedProjectTag,
      { type: context.tagType as TagType, id: id || "" }
    );
  }, [parsedProjectTag, context?.tagType, id, projectTag]);

  const baseTagName = useMemo(() => {
    if (!context?.tagType) {
      return projectTag;
    }
    return createTag(
      ...parsedProjectTag,
      { type: context.tagType as TagType, id: "" }
    );
  }, [parsedProjectTag, context?.tagType, projectTag]);

  return { projectTag, parsedProjectTag, fullTagName, baseTagName };
};
