import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SovState {
  sovIDs: Record<string, any>;
}

const initialState: SovState = {
  sovIDs: {},
};

const sovSlice = createSlice({
  name: 'sov',
  initialState,
  reducers: {
    setSovIDs: (state, action: PayloadAction<Record<string, any>>) => {
      state.sovIDs = action.payload;
    },
    updateSovID: (state, action: PayloadAction<{ key: string; value: any }>) => {
      state.sovIDs[action.payload.key] = action.payload.value;
    },
    resetSovIDs: (state) => {
      state.sovIDs = {};
    },
  },
});

export const { setSovIDs, updateSovID, resetSovIDs } = sovSlice.actions;
export default sovSlice.reducer;
