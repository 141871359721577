import React, { lazy, useState } from 'react';
import { URLS } from './urls';
import WarehouseMaterialsDetail from '../warehouse/materials/WarehouseMaterialsDetail';
import { useAppSelector } from '../../app/hooks';
import Starts from '../starts/Starts';

// Lazy load components
const GridView = lazy(() => import('../components/grid/GridView'));
const Dashboard = lazy(() => import('../dashboard/Dashboard'));
const ReportView = lazy(() => import('../reports/office-reports/OfficeReports'));
const WarehouseOrderDetail = lazy(() => import('../warehouse/WarehouseOrderDetail'));
const ProjectDetail = lazy(() => import('../projects/detail/Detail'));
const StartDetail = lazy(() => import('../starts/detail/StartDetail'));
const Overview = lazy(() => import('../components/overview/Overview'));
const OrgUnitDetail = lazy(() => import('../organizational-units/OrgUnitDetail'));
const FieldReports = lazy(() => import('../reports/field-reports/FieldReports'));
const WrappedItemView = lazy(() => import('../projects/project-items/WrappedItemView'));

export interface RouteType {
    id: string;
    name: string;
    path: string;
    component: React.ComponentType<any>;
}

interface SuiteType {
    id: string;
    name: string;
    routes: { [key: string]: RouteType };
}

interface Suites {
    [key: string]: SuiteType | RouteType;
}
 
// Define suites
export const suites: any = {
    root: {
        id: "root",
        name: "Root",
        path: URLS.root,
        component: Dashboard,
    },
    warehouse: {
        id: "warehouse",
        name: "Warehouse",
        routes: {
            warehouseMaterials: {
                id: "warehouseMaterials",
                name: "Warehouse Materials",
                path: URLS.warehouseMaterialPricing,
                component: WarehouseMaterialsDetail,
            },
            warehouseOrders: {
                id: "warehouseOrders",
                name: "Warehouse Orders",
                path: URLS.warehouseOrders,
                component: Overview,
            },
            warehouseOrderDetail: {
                id: "warehouseOrderDetail",
                name: "Warehouse Order Detail",
                path: URLS.warehouseOrderDetail,
                component: WarehouseOrderDetail,
            },
        }
    },
    app: {
        id: "app",
        name: "App",
        routes: {
            organizationalUnitDetail: {
                id: "organizationalUnitDetail",
                name: "Organizational Units",
                path: URLS.organizationalUnits,
                component: OrgUnitDetail,
            },
            projects: {
                id: "projects",
                name: "Projects",
                path: URLS.projects,
                component: Overview,    
            },
            projectDetail: {
                id: "project_detail",
                name: "Project Detail",
                path: URLS.projectDetail,
                component: ProjectDetail,    
            },
            proposal: {
                id: "proposalItems",
                name: "Proposal Items",
                path: URLS.proposal,
                component: WrappedItemView,
            },
            budget: {
                id: "budgetItems",
                name: "Budget Items",
                path: URLS.budget,
                component: WrappedItemView,
            },
            scheduleOfValues: {
                id: "scheduleOfValues",
                name: "Schedule of Values",
                path: URLS.scheduleOfValues,
                component: WrappedItemView,
            },
            contract: {
                id: "contractItems",
                name: "Contract Items",
                path: URLS.contract,
                component: WrappedItemView,
            },
            concretePricing: {
                id: "concretePricing",
                name: "Concrete Pricing",
                path: URLS.concreteMixDesignPricing,
                component: GridView,
            },
            aggregatePricing: {
                id: "aggregateQuoteList",
                name: "Aggregate Pricing List",
                path: URLS.aggregatePricing,
                component: GridView,
            },
            starts: {
                id: "start",
                name: "Start",
                path: URLS.starts,
                component: Starts,
            },
            startDetail: {
                id: "startDetail",
                name: "Start Detail",
                path: URLS.startDetail,
                component: StartDetail,
            },
            officeReports: {
                id: "reports",
                name: "Office Reports",
                path: URLS.reports,
                component: ReportView,
            },
            fieldReports: {
                id: "fieldReports",
                name: "Field Reports",
                path: URLS.fieldReports,
                component: FieldReports,
            },
        }
           
    },
};

