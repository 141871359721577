
import { useMemo, useEffect, useState } from "react";
import { useGetSovItemsQuery } from "../../dropdowns/dropdownApi";
import { useParams } from "react-router-dom";

const useSovMap = ({job_type, selectedItem}: any) => {
    const [sovIDs, setSovIDs] = useState<Map<string, any>>(new Map());
    const [selectedSov, setSelectedSov] = useState<Map<string, any>>(new Map());
    const { project_id } = useParams();

    const sovParams = useMemo(() => ({
        project_id: project_id || "",
        job_type_id: job_type?._id || ""
      }), [job_type, project_id]);
      const { data: sovResponse } = useGetSovItemsQuery(sovParams);
    
      useEffect(() => {
        if (sovResponse?.data) {
          const m = new Map();
          const set = sovResponse.data.find((sov: any) => sov.job_type_description === job_type?.job_type_description || sov?._id === job_type?._id);
          if (set) {
            set.items.forEach((sov: any) => {
              const key = `${sov.plan} - ${sov.elevation} - ${sov.option}${sov.option_code ? ` - ${sov.option_code}` : ""}`;
              m.set(key, sov);
            });
            setSovIDs(m);
          }
        }
      }, [sovResponse, job_type]);

    return { sovIDs, selectedSov, setSovIDs, setSelectedSov };
}

export default useSovMap;