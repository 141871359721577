import React, { useMemo, useState, useCallback } from "react";
import { Grid, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { DataGridPremium, GridActionsCellItem, GridCellParams, GridRowId, GridRowModel, GridRowParams } from "@mui/x-data-grid-premium";
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import PictureAsPdfSharpIcon from '@mui/icons-material/PictureAsPdfSharp';
import { useDeleteDataMutation, useGetDataQuery, useUpdateDataMutation } from "../../api/apiSliceV2";
import styles from "./document.module.css";
import FileUploadToolbar from "./FileUploadToolbar";
import ConfirmationDelete from "../dialog/ConfirmationDelete";
import useDocumentColumns from "./useDocumentColumns";
import appAPI from "../../api/appAPI";

const DocumentView = ({context, _id, tagName}:any) => {
  const theme = useTheme();
  const {project_id, budget_id, contract_id, proposal_id, start_id} = useParams();
  const [activeRow, setActiveRow] = useState<any>({});

  // Query params for the documents
  const queryParams = useMemo(() => ({ url: "/documents", tagName, params: { project_id, _id, budget_id, contract_id, proposal_id, start_id} }), 
  [project_id, _id, budget_id, contract_id, proposal_id, start_id]);

  // Get, delete, and update the documents
  const {data: documents, isLoading} = useGetDataQuery(queryParams);
  const [deleteData] = useDeleteDataMutation();
  const [updateData] = useUpdateDataMutation();
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);



  // Function to open the file viewer
  const handlePDFClick = useCallback(async (params: any) => {
    const fileId = params?.row?._id;
    const contentType = params?.row?.contentType;
    
    if (!fileId || !contentType) {
        console.error('Missing file information');
        return;
    }

    try {
        const response = await appAPI.get(`/documents/${fileId}`, {
            responseType: 'blob'
        });
        const file = new Blob([response.data], { type: contentType });
        const fileURL = URL.createObjectURL(file);

        // Open in new tab
        const newWindow = window.open(fileURL, '_blank');

        if (newWindow) {
            // Focus the new window
            newWindow.focus();

            // Clean up when the window loads the document
            newWindow.addEventListener('load', () => {
                // Revoke the blob URL after a short delay to ensure it's loaded
                setTimeout(() => {
                    URL.revokeObjectURL(fileURL);
                }, 1000);
            });

            // Backup cleanup in case load event doesn't fire
            setTimeout(() => {
                URL.revokeObjectURL(fileURL);
            }, 60000); // Cleanup after 1 minute if load event doesn't fire
        }
    } catch (error) {
        console.error('Error opening document:', error);
    }
  }, []);

  const handleConfirmationOpen =  useCallback((params:any) => {
    setActiveRow(params?.row);
    setConfirmationOpen(true);
  }, [setActiveRow, setConfirmationOpen]);

  // Function to update the file
  const processRowUpdate = useCallback(async (newRow: GridRowModel) => {
    try {
      await updateData({
        tagName,
        url: `/documents/${newRow._id}`,
        body: newRow
      }).unwrap();
      return newRow; // Return the updated row
    } catch (error) {
      console.error('Error updating file:', error);
      throw error; // Rethrow the error to indicate update failure
    }
  }, [tagName, updateData]);

  // Function to delete the file
  const handleDeleteDocument = useCallback(async (id:GridRowId) => { 
    try{
      await deleteData({
        tagName,
        url: `/documents/${id}`
      });
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  }, [deleteData, tagName]);

  // Column for the delete button
  const deleteColumn = useMemo(() => ({
    field: 'delete',
    type: 'actions',
    headerName: 'Delete',
    flex: 1,
    cellClassName: 'delete',
    getActions: (params:GridRowParams) => {
      return [
        <GridActionsCellItem
          icon={<DeleteForeverSharpIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => handleConfirmationOpen(params)}
          color="primary"
          sx={{ marginRight: 1 }}
        />
      ];
    },
  }), [handleConfirmationOpen]);

  // Update the PDF column
  const pdfColumn = useMemo(() => ({
    field: 'pdf',
    type: 'actions',
    headerName: 'PDF',
    flex: 1,
    cellClassName: 'pdf',
    getActions: (params: GridRowParams) => [
        <GridActionsCellItem
            icon={<PictureAsPdfSharpIcon />}
            label="Open PDF"
            className="textPrimary"
            onClick={() => handlePDFClick(params)}
            color="primary"
            showInMenu={false}
        />
    ],
  }), [handlePDFClick]);
  
  // Columns for the data grid
  const docColumns = useDocumentColumns();
  const currentColumns = useMemo(() => {
    return [pdfColumn, ...docColumns, deleteColumn || []];  
  }, [docColumns, pdfColumn, deleteColumn]);

  return (
      <>
      <Grid container justifyContent="center" alignItems="center" sx={{height: "100%", width: "100%", padding: 2}}>
        <Grid item sm={12} xs={12} sx={{height: "100%"}}>
          <DataGridPremium
            sx={{
              fontSize: 14, 
              fontWeight: 600, 
              opacity: 0.9,
              border: "1px solid rgba(255,255,255,0.5)",
              '& .MuiDataGrid-row:hover': {
                //backgroundColor: theme.palette.primary.main, 
                opacity: "0.8"
              },
              '& .MuiDataGrid-columnHeader': {
                whiteSpace: 'normal',
                lineHeight: '1.5',
                wordWrap: 'break-word',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: "bold",
                whiteSpace: 'normal',
                lineHeight: '1',
                wordWrap: 'break-word',
                textOverflow: 'ellipsis',
              },
            }}
            initialState={{
              pinnedColumns: { left: ['filename'], right: ['actions'] }
            }}
            getCellClassName={(params: GridCellParams<any, any, number>) => {
              return params.isEditable ? `${styles.editableCell}` : '';
            }}
            pinnedColumns={{ left: ['filename'] }}
            editMode="row"
            getRowId={(row) => row._id}
            density="compact"
            rows={documents?.data || []}
            columns={currentColumns || []}
            loading={isLoading}
            rowHeight={52}
            rowBuffer={10}
            processRowUpdate={processRowUpdate}
            slots={{ 
              toolbar: FileUploadToolbar,
            }}
            slotProps={{toolbar: {context, _id, tagName}}}
          />
        </Grid>
        <ConfirmationDelete 
          row={activeRow} 
          open={confirmationOpen} 
          setOpen={setConfirmationOpen} 
          handleDelete={handleDeleteDocument} 
        />
      </Grid>
    </>
  );
}

export default DocumentView;
