import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import useSovMap from "../hooks/useSovMap";

interface WithSovComponentProps {
  job_type?: any;
  selectedItem?: any;
  [key: string]: any; // Allow additional props
}

// HOC to wrap a form component with Sov autocomplete options
const withSovComponent = (WrappedComponent: React.ComponentType<any>, {job_type}: any) => {
  return (props: WithSovComponentProps) => {
    const { selectedItem } = useAppSelector((state: any) => state.api);
    const { sovIDs, selectedSov, setSovIDs, setSelectedSov } = useSovMap({ job_type: job_type || selectedItem?.job_type_id, selectedItem });

    const [autocompleteOptions, setAutocompleteOptions] = useState<any>({});

    useEffect(() => {
      if (sovIDs) {
        setAutocompleteOptions((prevOptions: any) => ({
          ...prevOptions,
          schedule_of_values_id: {
            data: Array.from(sovIDs.values()),
            options: Array.from(sovIDs.keys())
          },
          status: {
            data: ['ACTIVE', 'PENDING', 'INACTIVE'],
            options: ['ACTIVE', 'PENDING', 'INACTIVE']
          }
        }));
      }
    }, [sovIDs]);

    return (
      <WrappedComponent
        {...props}
        autocompleteOptions={autocompleteOptions}
        sovIDs={sovIDs}
        selectedSov={selectedSov}
        setSelectedSov={setSelectedSov}
      />
    );
  };
};

export default withSovComponent;