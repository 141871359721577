import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, ListItemText, Grid, Box } from '@mui/material';
import CustomButton from '../button/CustomButton';

interface SelectMenuProps {
  options: string[];
  selectedValue: string;
  label: string;
  onChange: (selected: string) => void;
  onApply: () => void; // Handler for the apply button
}

const SelectMenu: React.FC<SelectMenuProps> = ({ options, label, selectedValue, onChange, onApply }) => {
  const labelId = `${label.replace(/\s+/g, '-').toLowerCase()}-label`;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <FormControl sx={{ minWidth: "128px"}}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
              labelId={labelId}
              value={selectedValue}
              onChange={(event) => onChange(event.target.value as string)}
              renderValue={(selected) => selected}
              size="small"
              label={label}
              variant="outlined"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                PaperProps: {
                  style: {
                    maxHeight: "40vh",
                  },
                },
              }}
            >
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <CustomButton
            variant="outlined"
            color="primary"
            size="small"
            onClick={onApply}
            sx={{ height: '40px' }} // Ensure the button height matches the Select
          >
            Apply
          </CustomButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SelectMenu;
