import { Grid, IconButton } from "@mui/material";
import DetailHeader from "../../components/header/DetailHeader";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useState, useEffect, useMemo } from "react";
import { AutocompleteOptions } from "../../../utils/interfaces";
import { useGetSupervisorsQuery, useGetAllJobTypesQuery, useGetCustomersQuery } from "../../dropdowns/dropdownApi";
import { apiRouteMap } from "../../routes/urls";
import { showSnackbar } from "../../components/snackbar/snackbarSlice";
import { useUpdateDataMutation } from "../../api/apiSliceV2";
import { useLocation, useParams } from "react-router-dom";
import { setSelectedItem } from "../../components/stepper/stepperSlice";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NavigationHeader from "../../components/header/NavigationHeader";
import { FieldAccessibility, getExcludedFields, getNonEditableFields } from "../../../utils/headerUtil";
import { useTagName } from "../../components/hooks/useTagName";

const StartHeader = (props:any) => {
    const {context} = props;
    const { company, division } = useAppSelector((state: any) => state.auth);
    const { statuses } = useAppSelector((state: any) => state.dropdown);
    const dispatch = useAppDispatch();
    const params = useMemo(() => ({ company_id: company?._id }), [company]);
    const { data: customers } = useGetCustomersQuery(params);
    const { selectedItem} = useAppSelector((state: any) => state.api);
    const location = useLocation();
    const jobTypeParams = useMemo(() => ({ division_id: division?._id }), [division]);
    const { data: all_job_types } = useGetAllJobTypesQuery(jobTypeParams);
    const {data: supervisors} = useGetSupervisorsQuery(jobTypeParams);
    const [isHeaderVisible, setIsHeaderVisible] = useState<boolean>(true);
    const path = (location.pathname.split("/").length > 3) ?
    `/${location.pathname.split("/")[3]}`
    :
    `/${location.pathname.split("/")[1]}`;
    const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOptions>({});
    const [updateData] = useUpdateDataMutation();

    const { fullTagName } = useTagName({company, division, context});

      const fieldAccessibility: Record<string, FieldAccessibility> = {
        '_id': { editable: false, viewable: false },
        '_v': { editable: false, viewable: false },
        '__v': { editable: false, viewable: false },
        'project_id': { editable: false, viewable: true },
        'created_by': { editable: false, viewable: true },
        'created_at': { editable: false, viewable: true },
        'updated_by': { editable: false, viewable: true },
        'updated_at': { editable: false, viewable: true },
        'company_id': { editable: false, viewable: false },
        'division_id': { editable: false, viewable: false },
        'company_number': { editable: false, viewable: false },
        'division_number': { editable: false, viewable: false },
        'jc_export_date': { editable: false, viewable: true },
        'jc_exported_by': { editable: false, viewable: true },
        'ci_export_date': { editable: false, viewable: true },
        'ci_exported_by': { editable: false, viewable: true },
        'customer_id': { editable: true, viewable: true },
        'job_type_id': { editable: false, viewable: true },
    };
    
    const groupingConfig = [
        {key: 'Project', idField: 'project_id'},
        {key: 'Project', idField: 'job_number'},
        {key: 'Project', idField: 'customer_id'},
        {key: 'Project', idField: 'project'},
        {key: 'Project', idField: 'city'},
        {key: 'Project', idField: 'tax_rate'},
        {key: 'Start', idField: 'job_type_id'},
        {key: 'Start', idField: 'supervisor'},
        {key: 'Start', idField: 'status'},
        {key: 'Start', idField: 'notes'},
        {key: 'Meta Data', idField: 'created_by'},
        {key: 'Meta Data', idField: 'created_at'},
        {key: 'Meta Data', idField: 'updated_by'},
        {key: 'Meta Data', idField: 'updated_at'},
        {key: 'Office Reports', idField: 'jc_export_date'},
        {key: 'Office Reports', idField: 'jc_exported_by'},
        {key: 'Office Reports', idField: 'ci_export_date'},
        {key: 'Office Reports', idField: 'ci_exported_by'},
    ]
    
    const translationRequired = [
        "customer_id",
        "job_types",
        "job_type",
        "supervisor",
        "job_type_id"
    ];
    

    const excludedFields = getExcludedFields(fieldAccessibility);
    const nonEditableFields = getNonEditableFields(fieldAccessibility);

    const translateIds = (jsonData:any) => {
        try {
            for (const [key, val] of Object.entries(jsonData) as [string, any]) {
                if (translationRequired.includes(key)) {
                    switch (key) {
                        case "customer_id":
                            const customer = autocompleteOptions?.[key]?.data?.find((c:any) => c?.customer_description === val?.customer_description);
                            jsonData[key] = customer?._id || val; 
                            break;
                        case "supervisor":
                            const supervisor = autocompleteOptions?.[key]?.data?.find((s:any) => {
                                return s?.employee_name === val;
                            });
                            jsonData[key] = supervisor?._id || ""; 
                            break;
                        case "job_type_id":
                            const translated = autocompleteOptions?.[key]?.data?.find((j: any) => j?.job_type_description === jsonData[key]);
                            jsonData[key] = translated?._id;  
                            break;
                         default:
                            break;
                    }
                }
            }
            return jsonData;
        } catch (error) {
            console.log(error);
        }
        return jsonData;
    };

    const handleSave = async (formData:any) => {
        try {
            const body = translateIds(formData);
            const response = await updateData({
                tagName: `${fullTagName}-detail`,
                url: apiRouteMap.get(path).update(selectedItem?._id),
                body,
            }).unwrap();
            dispatch(setSelectedItem(response?.data));
        } catch (error: any) {
            console.error(error);
        }
    };

    useEffect(() => {
        setAutocompleteOptions({
            customer_id: {
                data: customers?.data || [],
                options: customers?.data?.map((customer: any) => customer.customer_description) || [],
            },
            job_type_id: {
                data: all_job_types?.data || [],
                options: all_job_types?.data?.map((job_type: any) => job_type.job_type_description) || [],
            },
            supervisor: {
                data: supervisors?.data || [],
                options: supervisors?.data?.map((supervisor: any) => supervisor.employee_name) || [],
            },
            status: {
                data: statuses || [],
                options: statuses || [],
            },
        });
    }, [customers, all_job_types, statuses, supervisors]);

    const toggleHeaderVisibility = () => {
        setIsHeaderVisible((current:boolean) => !current);
    }

    return(
        <Grid container spacing={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12}>
                <NavigationHeader  />
            </Grid>
            {isHeaderVisible &&
            <Grid item xs={12}>
                <DetailHeader
                    excludedFields={excludedFields}
                    autocompleteOptions={autocompleteOptions}
                    editable={path === "/starts"}
                    handleSave={handleSave}
                    path={path} 
                    nonEditableFields={nonEditableFields}  
                    groupingConfig={groupingConfig}              
                />
            </Grid>}
            <Grid item xs={12} sx={{ justifyContent: 'flex-start', display: 'flex', marginTop: '-1px' }}>
                <IconButton size="large" color="primary" onClick={toggleHeaderVisibility}>
                    {isHeaderVisible ? 
                    <ExpandLessIcon sx={{ fontSize: 24 }} /> 
                    : 
                    <ExpandMoreIcon sx={{ fontSize: 24 }} />
                    }
                </IconButton>
            </Grid>
        </Grid>
    );
}

export default StartHeader;