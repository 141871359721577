import React, { useEffect, useMemo, useState } from 'react';
import { 
    TextField, 
    List, 
    ListItemText, 
    Chip, 
    Box, 
    IconButton, 
    ListItemButton, 
    Grid 
} from '@mui/material';
import { useAppDispatch } from '../../../app/hooks';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import CustomButton from '../../components/button/CustomButton';
import { useCreateDataMutation, useGetDataQuery } from '../../api/apiSliceV2';
import { showSnackbar } from '../../components/snackbar/snackbarSlice';
import { apiRouteMap } from '../../routes/urls';

const WarehouseSearch = (props:any) => {
    const { order_id, orderType, handleClose, tagName } = props;
    const [query, setQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState<any>([]);
    const [selectedParts, setSelectedParts] = useState<any>([]);
    const queryParams = useMemo(() => ({ url: apiRouteMap.get('/warehouse-materials').url }), [apiRouteMap]);
    const {data: items} = useGetDataQuery(queryParams);
    const dispatch = useAppDispatch();
    const [createData] = useCreateDataMutation();

    useEffect(() => {
        if (items?.data) {
            setFilteredItems(items?.data);
        }

        return () => {
            setQuery("");
            setFilteredItems([]);
            setSelectedParts([]);
        }
    }, [items?.data]);

    const handleSearch = (event:any) => {
        const searchQuery = event.target.value.toLowerCase();
        const formatted = searchQuery.replace(/\s+/g, ''); // Remove spaces from search query

        setQuery(formatted);

        const filtered = items?.data?.filter((item:any) =>
            `${item.part_number}${(item.part_description).replace(/\s+/g, '')}`.toLowerCase().includes(searchQuery)
        );
        setFilteredItems(filtered);
    };

    const handleSelectPart = (part:any) => {
        if (!selectedParts.some((selectedPart:any) => selectedPart._id === part._id)) {
            setSelectedParts([...selectedParts, { ...part, order_quantity: 1 }]);
        }
    };

    const handleRemovePart = (partId:string) => {
        setSelectedParts(selectedParts.filter((part:any) => part._id !== partId));
    };

    const handleQuantityChange = (partId:string, quantity:number) => {
        setSelectedParts(selectedParts.map((part:any) => part._id === partId ? { ...part, order_quantity: quantity } : part));
    };

    const handleSubmit = async () => {
        try {
            if(selectedParts.length > 0){

                const formattedItems = selectedParts.map((item:any) => {

                    if(orderType === "pre-built-order"){
                        return {
                            "part_number": item.part_number,
                            "part_description": item.part_description,
                            "prebuilt_quantity": item.order_quantity,  
                            "unit_of_measure": item.unit_of_measure,
                            "segment_one": item?.cost_code?.split(".")[0],
                            "segment_two": item?.cost_code?.split(".")[1],
                        }
                    }
                    return {
                        "cost_type": item.cost_type,
                        "part_number": item.part_number,
                        "part_description": item.part_description,
                        "unit_of_measure": item.unit_of_measure,
                        "requested_quantity": item.order_quantity,
                        "segment_one": item?.cost_code?.split(".")[0],
                        "segment_two": item?.cost_code?.split(".")[1],
                    }  
                }); 

                const url = (orderType === "pre-built-order") ? 
                apiRouteMap.get('/prebuilt-orders/items').create(order_id)
                :  
                apiRouteMap.get('/warehouse/orders/starts').create(order_id);
                
                const response = await createData({
                    tagName,
                    body: formattedItems,
                    url
                }).unwrap();
                dispatch(showSnackbar({ message: response?.message || 'Data creation is successful', type: 'success' }));

                setQuery("");
                setFilteredItems([]);
                setSelectedParts([]);
                handleClose();
            
            }
        } catch (error:any) {
            dispatch(showSnackbar({ message: error?.data?.message || error?.message, type: 'error' }));

        }
        
    };

    const CustomDeleteIcon = ({ onClick }:any) => (
        <IconButton onClick={onClick} size="small" sx={{ marginRight: 'auto' }}>
            <HighlightOffSharpIcon color='error' fontSize="small" />
        </IconButton>
    );

    return (
        <Grid style={{display: "flex", flexDirection: "column", zIndex: 99, overflowX: "scroll", maxHeight: "800px", width: "100%"}}>
            <Grid container style={{padding: "1rem", width: "100%", alignItems: "center"}} gap={1}>
                <Grid item md={9} xs={12}>
                    <TextField
                        label="Search Material"
                        variant="outlined"
                        fullWidth
                        value={query}
                        size="small"
                        hiddenLabel
                        onChange={handleSearch}
                    />
                </Grid>
                <Grid item md={2} xs={12} sx={{display: "flex"}}>
                    <CustomButton sx={{padding: 1, width: "100%"}} onClick={handleSubmit} variant="outlined" color="primary" size="small">
                        Add Materials
                    </CustomButton>
                </Grid>    
            </Grid>

            <Grid style={{ display: "flex", overflowX: "auto", width: "100%" }}>
                <Grid style={{display: "flex", justifyContent: "flex-start"}}>
                    {query && (
                        <List>
                            {filteredItems.map((item:any) => (
                                <ListItemButton key={item._id} onClick={() => handleSelectPart(item)}>
                                    <ListItemText 
                                        primary={`${item.part_number} - ${item.part_description}`} 
                                        secondary={
                                        <Grid style={{display: "flex", flexDirection: "column"}}>
                                            <span>Unit of Measure: {item.unit_of_measure}</span>
                                            <span>Cost Type: {item.cost_type}</span>
                                            <span>Cost Code: {item.cost_code}</span>
                                        </Grid> 
                                    } />
                                </ListItemButton>
                            ))}
                        </List>
                    )}
                </Grid>

                <Grid style={{display: "flex", flexDirection: "column"}}>
                    {selectedParts.map((part:any) => (
                        <Box key={part._id} sx={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end", m: 1 }}>
                            <Chip
                                label={`${part.part_number} - ${part.part_description}`}
                                icon={<CustomDeleteIcon onClick={() => handleRemovePart(part._id)} />}
                            />
                            <TextField
                                label="Quantity"
                                type="number"
                                size="small"
                                value={part.order_quantity}
                                onChange={(e) => handleQuantityChange(part._id, parseInt(e.target.value, 10))}
                                sx={{ ml: 1, width: '100px' }}
                            />
                        </Box>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default WarehouseSearch;
