import { parseISO, isValid, format } from "date-fns";

export const formatDateString = (dateString: string) => {
    try {
      const date = parseISO(dateString);
      if (isValid(date)) {
        return format(date, 'MMM d, yyyy');  
      }
      return '';  
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Error';
    }
  };
  
  export const formatDateStringWithTime = (dateString:string) => {
    try {
      const date = parseISO(dateString);
      if (isValid(date)) { // Checks if the parsed date is valid
        const dateString = format(date, 'PPpp'); // Example format: 'Apr 29, 2020, 5:30 PM'
        return dateString;
      }
      return "";  
    } catch (error) {
      console.error('Error formatting date:', error);
      return "Error";
    }
  };