import { useAppDispatch } from "../../../../app/hooks";
import CustomButton from "../../../components/button/CustomButton";
import { useUpdateDataMutation } from "../../../api/apiSliceV2";
import { ActionType } from "./ActionType";
                                
const RequestOrder: React.FC<ActionType> = (props:any) => {
    const {row, tagName} = props;
    const [updateData] = useUpdateDataMutation();

        const handleStatusUpdate = async () => {
        const requestData = {
            ...row,
            order_status: "REQUESTED"
        };
        await updateData({
            tagName,
            url: `/warehouse/orders?id=${row?._id}`,
            body: requestData,
        }).unwrap();
    }
    return (
        <CustomButton 
            size="small" 
            color="success" 
            variant="contained" 
            sx={{color: "white"}} 
            onClick={handleStatusUpdate}
        >
            Request Order
        </CustomButton>
    )
}

export default RequestOrder; 