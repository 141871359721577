import { TagTypes } from "../../api/tagTypes";
import lotColumns from "../../components/columns/lotColumns";
import ManageLots from "./ManageLots";
import RequestStart from "./RequestStart";

//Request Start
const requestStart:any = [
    {
        route: "/starts",
        label: "Request Start",
        Component: RequestStart, 
        actionType: "submit",
        tagType: TagTypes.STARTS,
        idType: "project_id", 
        saveUrl: (id:string) => `/lots?project_id=${id}`,
        getColumns: () => [],
        isOptional: false,
        redirect: (project_id:string, id:string) => `/projects/${project_id}/starts/${id}`
    }
];

//Start Item Creation
const createLotAndStartItem:any = [
    {
        route: "/starts",
        label: "Manage Lots",
        Component: ManageLots, 
        actionType: "submit",
        tagType: TagTypes.STARTS,
        idType: "project_id",
        saveUrl: (id:string) => `/lots?project_id=${id}`,
        getColumns: () => lotColumns?.useLotColumns(),
        isOptional: true
    }
];

const startsSteps = {
    requestStart,
    createLotAndStartItem
};

export default startsSteps;