import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Autocomplete, Grid, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { DataGridPremium, GridEventListener, GridRowEditStopReasons, GridRowModesModel } from '@mui/x-data-grid-premium';
import { useParams } from 'react-router-dom';
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import CustomButton from '../../components/button/CustomButton';
import styles from './detail.module.css';
import { apiSliceV2, useCreateDataMutation, useGetDataQuery, useUpdateDataMutation } from '../../api/apiSliceV2';
import { showSnackbar } from '../../components/snackbar/snackbarSlice';
import { apiRouteMap } from '../../routes/urls';
import CustomGridToolbar from '../../components/grid/toolbars/CustomGridToolbar';

const ManageLots = ({ step, columns, tagName }:any) => {
  const {selectedItem} = useAppSelector((state:any) => state.api);
  const {project_id, start_id} = useParams();
  const context = step?.route?.split("/")[1];
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [excludedLots, setExcludedLots] = useState<any>([]);
  const dispatch = useAppDispatch();
  const [lotNumber, setLotNumber] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [orientation, setOrientation] = useState<string>("");
  const lotNumberRef = useRef<any>(null);

  const queryParams = useMemo(() => {
    if (!context) return null;
    return {
      tagName: `${tagName}/lots`,
      url: 'lots',
      params: { 
        project_id,
      }
    };
  }, [project_id, context, selectedItem]);
  
  const {data: response } = useGetDataQuery(queryParams);
  const [createData, {isLoading}] = useCreateDataMutation();
  const [updateData] = useUpdateDataMutation();


  useEffect(() => {
    if(response?.data){
      const filter = Array.isArray(response?.data) && response?.data?.filter((item:any) => {
        return item?.startDetails?.some((startItem:any) => startItem._id === start_id || startItem._id === selectedItem?._id);
      });
      setExcludedLots(filter);
    }
  }, [response?.data]);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
 
  const handleRowModesModelChange = (rowModesModel: GridRowModesModel) => {
    setRowModesModel(rowModesModel);
  }

  const resetState = () => {
    setLotNumber("");
    setAddress("");
    setOrientation("");
  }

  const handleCreateLot = async () => {
    try {
      if(project_id || selectedItem){
        const id = project_id  || selectedItem?.project_id;
        const data = {
          lot_number: lotNumber,
          address,
          garage_orientation: orientation
        }
 
        const payload = await createData({
          tagName: [tagName, `${tagName}/lots`],
          url: apiRouteMap.get("/lots").createItem(id,selectedItem?._id || start_id),
          body: data, 
        }).unwrap();
        //dispatch(apiSliceV2.util.invalidateTags());
        dispatch(showSnackbar({ message: payload?.message || 'Operation successful', type: 'success' }));
        resetState();
      }
    } catch (error:any) {
      const errorMessage = error?.data?.message || 'Failed to create data';
      dispatch(showSnackbar({ message: `Error: ${errorMessage}`, type: 'error' }));
    } 
};
 
  const handleSaveSelection = async () => {
    try {
      if(rowSelectionModel.length > 0){
        for(const entry of rowSelectionModel){
          await updateData({url: `/lots?id=${entry}&start_id=${selectedItem?._id || start_id}`}).unwrap();
        }
        dispatch(apiSliceV2.util.invalidateTags([tagName, `${tagName}/lots`]));
      }
    } catch (error:any) {
      const errorMessage = error?.data?.message || 'Failed to create data';
      dispatch(showSnackbar({ message: `Error: ${errorMessage}`, type: 'error' }));
    }
  }

  const handleSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel(rowSelectionModel);
  }

  const isRowSelectable = (params: GridRowParams, excludedLots: any[], selectedItem: any) => {
    // Check if the lot is in the excludedLots array
    const found = excludedLots?.some((lot: any) => lot?._id === params.id);
    if (found) return false;
  
    // Get the selectedItem's job_type_id
    const selectedJobTypeId = selectedItem?.job_type_id?._id;
  
    // // Handle cases where startDetails array is empty or contains only empty objects
    if (
      !params.row.startDetails || 
      params.row.startDetails.length === 0 || 
      params.row.startDetails.every((start: any) => Object.keys(start).length === 0)
    ) {
      return true; // If there are no meaningful startDetails, the row is selectable
    }
  
    // Check if any start in startDetails has a matching job_type_id
    const hasMatchingJobType = params.row.startDetails?.some(
      (start: any) => start.job_type_id === selectedJobTypeId
    );
  
    // Return false if a matching job_type_id is found, otherwise true
    return !hasMatchingJobType;
  };
    
  const handleRowSelection = (params: GridRowParams) => {
    return isRowSelectable(params, excludedLots, selectedItem);
  };
  
  const handleRowClassName = (params: any) => {
    return isRowSelectable(params, excludedLots, selectedItem) ? '' : `${styles.inactive}`;
  };
  
  const processRowUpdate = useCallback(async (newRow: any) => {
    try {
      const response = await updateData({
        tagName: [tagName, `${tagName}/lots`],
        body: newRow,
        url: apiRouteMap.get("/lots").update(newRow?._id)
      }).unwrap();
      dispatch(showSnackbar({ message: response?.message, type: 'success' }));
      return newRow;
    } catch (error:any) {
      dispatch(showSnackbar({ message: error?.data?.message || error?.message, type: 'error' }));
    }
  }, [updateData, context, apiRouteMap]);

  return (
    <Grid container spacing={2} justifyContent="center" sx={{width: "100%", height: "100%", overflowX: "scroll"}}>
      <Grid container justifyContent="flex-end" alignItems="flex-end" spacing={1} sx={{px: "1rem"}}>
        <Grid item md={3} xs={12}>
          <TextField
            size="small"
              variant="filled"
              autoFocus
              margin="dense"
              label="Lot Number"
              type="string"
              fullWidth
              name="lot_number"
              value={lotNumber || ""}
              onChange={e => setLotNumber(e.target.value)}
              inputRef={lotNumberRef}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            size="small"
            variant="filled"
            margin="dense"
            label="Address"
            type="string"
            fullWidth
            name="address"
            value={address || ""}
            onChange={e => setAddress(e.target.value)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Autocomplete
            size="small"                     
            options={["R", "L"]}
            getOptionLabel={(option) => option || ""}
            renderInput={(params) => (
              <TextField
                
                {...params} 
                variant="filled" 
                label="Orientation" 
                margin="dense" 
              />
            )} 
            value={orientation}
            onChange={(event, newValue) => setOrientation(newValue || "")}               
          />
        </Grid>
        <Grid item md={3} xs={12} sx={{mb:0.36, display: "flex", justifyContent: "flex-start"}}>
          <CustomButton color="primary" size="small" variant="outlined" onClick={handleCreateLot}>Create Lot</CustomButton>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{height: "auto", maxHeight: "40vh"}}>
        <DataGridPremium
          sx={{
            fontSize: 14, 
            fontWeight: 500, 
            border: "1px solid rgba(255,255,255,0.5)"
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                document: false,
                created_at: false,
                created_by: false,
                updated_at: false,
                updated_by: false
              },
            },
            pinnedColumns: {
              left: [ GRID_CHECKBOX_SELECTION_COL_DEF.field, 'lot_number'],
              right: ['actions']
            },
            sorting: {
              sortModel: [
                {
                  field: 'lot_number',
                  sort: 'asc',
                },
              ],
            },
          }} 
          slots={{
            toolbar: CustomGridToolbar,
          }}
          slotProps={{
            toolbar: {
              columns: columns,
            }
          }}
          checkboxSelection
          editMode="row"
          getRowId={row => row?._id}
          isRowSelectable={(params:any) => handleRowSelection(params)}
          density="compact"
          rows={response?.data || []}
          columns={columns || []}
          loading={isLoading}
          rowHeight={80}
          rowBuffer={10}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowSelectionModelChange={handleSelectionModelChange}
          processRowUpdate={processRowUpdate}
          onRowEditStop={handleRowEditStop}
          getRowClassName={(params:any) => handleRowClassName(params)}
          hideFooter
        />
      </Grid>

      <Grid item xs={12} sx={{display: "flex", justifyContent: "flex-end"}}>
        {/* {!completed[activeStep] && */}
        <CustomButton color="primary" size="small" sx={{marginLeft: 1}} variant="contained" onClick={handleSaveSelection}  component="label">
            Add Lots to Start
        </CustomButton>
        {/* {completed[activeStep] &&
        <CustomButton color="primary" size="small" sx={{marginLeft: 1}} variant="contained" onClick={handleClose}  component="label">
            Close
        </CustomButton>}  */}
      </Grid>
    </Grid>
  );
};

export default ManageLots;
