import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Grid, Box, MenuItem, Select, useTheme } from '@mui/material';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import styles from './NavigationHeader.module.css';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import CustomButton from '../button/CustomButton';
import { setSelectedItem } from '../stepper/stepperSlice';
import { useGetProjectsQuery, useGetStartsQuery } from '../../dropdowns/dropdownApi';
import { setSelectedProject } from '../../projects/projectSlice';
import { useGetDataQuery } from '../../api/apiSliceV2';

const navContext = [ 
  {
    route: "starts", 
    getUrl: (project_id:string) => `starts?project_id=${project_id}`,
    id: 'start_id',
    value: 'subjob_number'
  },
  {
    route: "office-reports", 
    getUrl: (project_id:string) => `starts?project_id=${project_id}`,
    id: 'start_id',
    value: 'subjob_number'

  },
  {
    route: "field-reports", 
    getUrl: (project_id:string) => `starts?project_id=${project_id}`,
    id: 'start_id',
    value: 'subjob_number'

  },
  {
    route: "warehouse-orders", 
    getUrl: (project_id:string) => `starts?project_id=${project_id}`,
    id: 'start_id',
    value: 'subjob_number'

  },
]

const startMenuItems:any = {
  "starts": [
    "starts",
    'warehouse-orders', 
    'office-reports',
    'field-reports',
  ],
  'warehouse-orders': [,
    'warehouse-orders',
    'starts',
    'office-reports',
    'field-reports'
  ],
  'office-reports': [
    "office-reports",
    'field-reports',
    'starts',
    'warehouse-orders' 
  ],
  'field-reports': [
    'field-reports',
    "office-reports",
    'starts',
    'warehouse-orders'
  ]
}
//console.log
const NavigationHeader = (props:any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { project_id, start_id } = useParams();
  const {company, division} = useAppSelector((state:any) => state.auth);
  const {selectedProject, selectedItem, activeTag} = useAppSelector((state:any) => state.api);
  const theme = useTheme();
  const [selectedStart, setSelectedStart] = useState<any>(null);
  const [locationName, setLocationName] = useState<string>("");
  const context:any = navContext?.find((c:any) => c?.route === locationName);
  const [loading, setLoading] = useState<boolean>(false);

  const params = useMemo(() => ({
    company_id: company?._id, 
    division_id: division?._id
  }),[company, division]);
  const {data: projects, isLoading: isProjectsLoading} = useGetDataQuery({url: `/projects`, params}, {skip: !params, refetchOnMountOrArgChange: true});
  const startParams = useMemo(() => ({project_id: project_id || selectedItem?.project_id}), [project_id, selectedItem?.project_id]);
  const { data: starts, isLoading: isStartsLoading } = useGetDataQuery({url: `/starts`, params: startParams}, {skip: !startParams, refetchOnMountOrArgChange: true});

  useEffect(() => {
    setLoading(isProjectsLoading || isStartsLoading);
  }, [isProjectsLoading, isStartsLoading]);
  
  useEffect(() => {
    if(projects){
      const project = projects?.data?.find((p:any) => {
        return p?._id === (project_id || selectedItem?.project_id)
      });
      setCurrentProject(project);
      dispatch(setSelectedProject(project));
    }
  },[project_id, projects, selectedItem?.project_id]);

  useEffect(() => {
    const name = (location.pathname.split("/").length > 3) ? 
      location.pathname.split("/")[3] 
    : 
      location.pathname.split("/")[1]; //?.slice(0, -1);
    setLocationName(name);
  },[location, context]);

  useEffect(() => {
    if(starts && start_id){
      const start = starts?.data?.find((start:any) => start?._id === (start_id || selectedItem?._id));
      setSelectedStart(start);
      dispatch(setSelectedItem({...selectedProject, ...start}));
    }
  }, [starts, selectedItem?._id, start_id]);

  useEffect(() => {
    if (starts && start_id) {
      const start = starts?.data?.find((start: any) => start?._id === (start_id || selectedItem?._id));
      setCurrentStart(start);
      dispatch(setSelectedItem(start));
    }
  }, [starts, selectedItem?._id, start_id]);

  const handleProjectChange = (event:any) => {
    const project = projects?.data && projects?.data?.find((p:any) => p.job_number === event.target.value);
    setSelectedProject(project);
    navigate(`/projects/${project?._id}`);
  };

  const handleRouteChange = (event:any) => {
    const contextPath = event?.target?.value;
    setLocationName(contextPath)
    navigate(`/projects/${(selectedProject?._id || project_id)}/${contextPath}/${(selectedStart?._id || start_id)}`);
  }

  const handleItemIdChange = (event:any) => {
    const item:any = starts?.data?.find((s:any) => s?.[context?.value] === event.target.value);
    setSelectedStart(item);
    dispatch(setSelectedItem(item));
    const contextPath = location.pathname.split("/")[3];
    navigate(`/projects/${selectedProject?._id}/${contextPath}/${item?._id}`);
  };

  const handleBackNavigation = () => {
    navigate(-1);
  };

  const [currentProject, setCurrentProject] = useState<any>(null);

  useEffect(() => {
    if (selectedProject) {
      setCurrentProject(selectedProject);
    }
  }, [selectedProject]);

  const [currentStart, setCurrentStart] = useState<any>(null);

  return (
    <Grid container alignItems="center" justifyContent="space-between" sx={{height: "auto"}}>
      <Grid item>
        <CustomButton color="primary" variant="outlined" onClick={handleBackNavigation} startIcon={<ArrowBackIosNewSharpIcon />} size="small">
          Back
        </CustomButton>
      </Grid>
      <Grid item xs sx={{fontWeight: 800}}>
        <Box sx={{width: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <Box>
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "end",width: "auto"}}>
              <Select
                value={currentProject?.job_number || ''}
                defaultValue={currentProject?.job_number}
                onDoubleClick={() => navigate(`/projects/${currentProject?._id}`)} 
                onChange={handleProjectChange}
                displayEmpty
                className={styles.title}
                variant="standard"
                margin='dense'
                size='small'
                disabled={loading}
                sx={{ ml: 1, width: "100%", border: "none", fontSize: 32, fontWeight: 500, color: theme.palette.primary.main, maxHeight: "40vh"}}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom", // Position the dropdown below
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  PaperProps: {
                    style: {
                      maxHeight: "60vh", // Restrict the max height of the menu
                    },
                  },
                }}
              >
                {projects?.data && projects?.data?.map((project:any) => (
                  <MenuItem key={project?._id} value={project?.job_number}>
                    {project?.job_number}
                  </MenuItem>
                ))}
              </Select>
          
              {starts?.data?.length > 0 && start_id && (
                <Select
                  value={currentStart?.[context?.value] || ''}
                  defaultValue={currentStart?.[context?.value]}
                  onChange={handleItemIdChange}
                  displayEmpty
                  className={styles.title}
                  variant="standard"
                  margin='dense'
                  size='small'
                  color='primary'
                  disabled={loading}
                  sx={{ ml: 1, width: "100%", border: "none", fontSize: 32, fontWeight: 500, maxHeight: "40vh"}}
                >
                  {starts?.data?.map((item: any) => {
                    const formattedValue = item?.[context?.value]?.replace('-', ' ').toUpperCase(); // Replace '-' with space and set to uppercase
                    return (
                      <MenuItem key={item?.[context?.value]} value={item?.[context?.value]}>
                        {formattedValue}
                      </MenuItem>
                    );
                  })}
                </Select>   
            )}
          </Box>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", gap: 1, width: "auto"}}>
            {startMenuItems?.[`${locationName}`]?.includes(locationName)&& (
            <Box sx={{width: "auto"}}>
              <Select
              value={locationName}
              defaultValue={locationName}
              onChange={handleRouteChange}
              displayEmpty
              className={styles.title}
              variant="standard"
              margin='dense'
              size='small'
              sx={{ ml: 1, width: "auto", border: "none", fontSize: 22, fontWeight: 500}}
              >
                {startMenuItems?.[`${locationName}`]?.map((route:any) => (
                  <MenuItem defaultValue={locationName} key={route} value={route}>
                    {route}
                  </MenuItem>
                ))}
              </Select>
            </Box>)}
          </Box>
        </Box>
          </Box>
      </Grid>
      <Grid item className={styles.spacer}></Grid>
    </Grid>
  );
};

export default NavigationHeader;



