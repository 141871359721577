    export type FieldAccessibility = {
        editable: boolean;
        viewable: boolean;
    };

    export type FieldAccessibilityMap = Record<string, FieldAccessibility>;

    /**
     * Get fields that are neither viewable nor editable
     * @param fieldAccessibility Object mapping field names to their accessibility
     * @returns Array of field names that should be excluded
     */
    export const getExcludedFields = (fieldAccessibility: FieldAccessibilityMap): string[] => 
        Object.entries(fieldAccessibility)
            .filter(([_, value]) => !value.viewable && !value.editable)
            .map(([key, _]) => key);

    /**
     * Get fields that are viewable but not editable
     * @param fieldAccessibility Object mapping field names to their accessibility
     * @returns Array of field names that are non-editable
     */
    export const getNonEditableFields = (fieldAccessibility: FieldAccessibilityMap): string[] => 
        Object.entries(fieldAccessibility)
            .filter(([_, value]) => !value.editable)
            .map(([key, _]) => key);

    /**
     * Get fields that are both viewable and editable
     * @param fieldAccessibility Object mapping field names to their accessibility
     * @returns Array of field names that are editable
     */
    export const getEditableFields = (fieldAccessibility: FieldAccessibilityMap): string[] => 
        Object.entries(fieldAccessibility)
            .filter(([_, value]) => value.viewable && value.editable)
            .map(([key, _]) => key);

    /**
     * Get all viewable fields (both editable and non-editable)
     * @param fieldAccessibility Object mapping field names to their accessibility
     * @returns Array of field names that are viewable
     */
    export const getViewableFields = (fieldAccessibility: FieldAccessibilityMap): string[] => 
        Object.entries(fieldAccessibility)
            .filter(([_, value]) => value.viewable)
            .map(([key, _]) => key);