import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { useParams } from 'react-router-dom';
import { useGetJobTypesQuery, useGetAllJobTypesQuery } from '../../dropdowns/dropdownApi';

interface UseJobTypesProps {
  route: string;
}

const useJobTypes = ({route}: UseJobTypesProps) => {
  const {project_id} = useParams();
  const { division } = useAppSelector((state: any) => state.auth);

  const params = useMemo(() => ({
    project_id: project_id || "",
    division_id: division?._id || ""
  }), [division, project_id]);

  const { data: projectJobTypes } = useGetJobTypesQuery(params, {
    skip: route !== "/prebuilt-orders",
    refetchOnMountOrArgChange: true
  });
  
  const { data: divisionJobTypes } = useGetAllJobTypesQuery({
    division_id: division?._id || ""
  }, {
    skip: route === "/prebuilt-orders",
    refetchOnMountOrArgChange: true
  });

  const [jobTypes, setJobTypes] = useState<any[]>([]);

  useEffect(() => {
    if (route === "/prebuilt-orders" && projectJobTypes?.data) {
      setJobTypes(projectJobTypes.data);
    } else if (route !== "/prebuilt-orders" && divisionJobTypes?.data) {
      setJobTypes(divisionJobTypes.data);
    } else {
      setJobTypes([]);
    }
  }, [projectJobTypes, divisionJobTypes, route]);

  return { jobTypes };
};

export default useJobTypes;
