import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Grid, Tab, Tabs, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { 
  DataGridPremium, 
  GridActionsCellItem, 
  GridCellParams, 
  GridEventListener, 
  GridRowEditStopReasons, 
  GridRowId, 
  GridRowModes, 
  GridRowModesModel,
  GridColDef, 
  DataGridPremiumProps,
  GridFilterModel
} from "@mui/x-data-grid-premium";
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import CancelIcon from "@mui/icons-material/Close";
import PanelContent from "./Panel";
import { setActiveTag, setSelectedItem } from "../../projects/projectSlice";
import styles from './detail.module.css';
import DocumentView from "../document-viewer/DocumentView";
import { useGetDataQuery, useUpdateDataMutation, useDeleteDataMutation } from "../../api/apiSliceV2";
import { showSnackbar } from "../snackbar/snackbarSlice";
import { apiRouteMap } from "../../routes/urls";
import StartHeader from "../../starts/detail/Header";
import ConfirmationDelete from "../dialog/ConfirmationDelete";
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';
import { createTag, parseTag, TagTypes } from "../../api/tagTypes";
import { TagType } from "../../api/tagTypes";
import GetAppSharpIcon from '@mui/icons-material/GetAppSharp';

interface DetailViewProps {
  tabs: any;
} 
 
const DetailView: React.FC<DetailViewProps> = ({ tabs }) => {
  const { company, division } = useAppSelector((state: any) => state.auth);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const routeSegment = `/${location.pathname.split("/")[3]}`;
  const {project_id, start_id } = useParams();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = useState<any>([]);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);
  const [dialog, openDialog] = useState(false);
  const [editModes, setEditModes] = useState(new Map());
  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(undefined);
  const [filterTimeout, setFilterTimeout] = useState<any>(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const [context, setContext] = useState<any>({});
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [tabsHeight, setTabsHeight] = useState(0);
  const [outerHeight, setOuterHeight] = useState<string>("");
  const theme = useTheme();
  const tabsRef = useRef<HTMLDivElement>(null);
  const [activeRow, setActiveRow] = useState<any>({});
  const [open, setOpen] = useState<any>(false);
  const navigate = useNavigate();
  const projectTag = useMemo(() => createTag(
    company?._id ? { type: TagTypes.COMPANIES, id: company._id } : undefined,
    division?._id ? { type: TagTypes.DIVISIONS, id: division._id } : undefined,
    project_id ? { type: TagTypes.PROJECTS, id: project_id } : undefined,
  ), [company?._id, division?._id, project_id]);

  const parsedProjectTag = useMemo(() => parseTag(projectTag), [projectTag]);

  // Generate a unique tag name for the stepper
  const tagName = useMemo(() => createTag(
    ...parsedProjectTag,
    context?.tagType ? { type: context.tagType as TagType, id: start_id || "" } : undefined,
  ), [parsedProjectTag, context?.tagType, start_id]);

  useEffect(() => {
    dispatch(setActiveTag(tagName));
  }, [tagName, dispatch]);

  const detailParams = useMemo(() => ({
    url: '/starts/details',
    params: { 
      id: start_id 
    },
    tagName: `${tagName}-detail`
  }), [start_id, tagName]);

  const isOrganizationUnitsPath = useMemo(() => 
    location.pathname.startsWith('/organizational-units'),
  [location.pathname]);

  const { data: detail } = useGetDataQuery(detailParams, {
    skip: isOrganizationUnitsPath 
  });

  useEffect(() => {
    if(detail) dispatch(setSelectedItem(detail));
  },[detail, dispatch]);

  const queryParams = useMemo(() => ({ 
    url: context?.route, 
    params: {
      ...context?.params,
      page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      ...Object.fromEntries(
        filterModel?.items
          ?.filter((item: any) => item.value !== undefined && item.value !== '')
          ?.map((item: any) => [item.field, item.value]) || []
      )
    }, 
    tagName
  }), [context, start_id, project_id, company, division, paginationModel, filterModel]);

  const {data: response,  isLoading} = useGetDataQuery(queryParams, {
    skip: !context?.route || !tagName
  });
  const [updateData] = useUpdateDataMutation();
  const [deleteData] = useDeleteDataMutation();

  useEffect(() => {
    if(response?.data) setRows(response?.data);
  },[response?.data, dispatch]);

  const handleTabChange = useCallback((event: any, newValue: number) => {
    setCurrentTabIndex(newValue);
  }, []);

  // Optimize context setting
  useEffect(() => {
    const c: any = tabs?.find((c: any) => c?.index === currentTabIndex);
    setContext(c);
    setRows([]);
  }, [currentTabIndex, tabs]);

  // Optimize resize effect
  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Measure the height of the Tabs component after it renders
  useEffect(() => {
    if (tabsRef?.current) {
      setTabsHeight(tabsRef.current.offsetHeight || 0);
    }
  }, [viewportHeight]);

  // Update outer height based on viewport height and tabs height
  useEffect(() => {
    setOuterHeight(`calc(${viewportHeight}px)`);
  }, [viewportHeight, tabsHeight]);

  const onFilterChange = useCallback((newFilterModel: GridFilterModel) => {
    //Debounce filter change
    clearTimeout(filterTimeout);
    const newTimeout = setTimeout(() => {
      setFilterModel(newFilterModel);
    }, 150);
    setFilterTimeout(newTimeout);
  }, [filterModel]);

  const handleDetailPanelExpandedRowIdsChange = (newExpandedRowIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newExpandedRowIds.length > 1 ? [newExpandedRowIds[newExpandedRowIds.length - 1]] : newExpandedRowIds);
  };

  const getDetailPanelHeight = React.useCallback<NonNullable<DataGridPremiumProps['getDetailPanelHeight']>>(() => 'auto' as const, []);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleConfirmDeletion = useCallback((id: GridRowId) => {
    const row = response?.data?.find((r:any) => r?._id === id || r?.item_id === id || r?.id === id);
    setActiveRow(row);
    setOpen(true);
  }, [response?.data]);

  const getPath = (context: any) => {
    if (context?.label === 'Warehouse Orders') {
        return '/warehouse/orders';
    }
    return context?.route
  };

  const path = getPath(context);

  const handleDelete = useCallback(async (id: GridRowId) => {
    const url = apiRouteMap.get(path).delete(id, start_id);
    await deleteData({ tagName, url });
  }, [deleteData, context?.route, start_id, tagName]);

  const handleDeleteAll = useCallback(async () => {
    try {
      const ids = response?.data?.map((row: any) => row?._id || row?.id);
      await deleteData({
        ids,
        url: apiRouteMap.get(path).url,
        tagName
      });
      dispatch(showSnackbar({ message: `Documents deleted`, type: 'success' }));
    } catch (error) {
      console.error("Error deleting data: ", error);
    }
  }, [response?.data, deleteData, context?.route, tagName, dispatch]);

  const handleCancelClick = useCallback((id: GridRowId) => () => {
    setEditModes(prevModes => new Map(prevModes).set(id, false));
    openDialog(false);
    setRowModesModel(prevModel => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));

    setRows((prevRows:any) => prevRows.filter((row: any) => !(row._id === id && row.isNew)));
  }, []);

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    try {
      console.log(path, apiRouteMap.get(path))
      const sanitizedRow = Object.keys(newRow).reduce((acc: any, key: string) => {
        const value = newRow[key];
  
        // If the value is an object and has an _id, use the _id, otherwise use the value itself
        if (value && typeof value === 'object' && value._id) {
          acc[key] = value._id;
        } else {
          acc[key] = value;
        }
  
        return acc;
      }, {});
  
      // Use the _id from the sanitized row for the update request
      const id = sanitizedRow._id || sanitizedRow.objectid;
  
      // Make the API call with the sanitized row
      const response = await updateData({
        tagName, 
        body: sanitizedRow,   
        url: apiRouteMap.get(path).update(id)   
      }).unwrap();
  
      dispatch(showSnackbar({ message: response?.message || 'Update successful', type: 'success' }));
  
      return sanitizedRow;  // Return the sanitized row for the grid
  
    } catch (error: any) {
      dispatch(showSnackbar({ message: error?.data?.message || error?.message || 'Update failed', type: 'error' }));
      return oldRow;  // Revert to the old row in case of failure
    }
  }, [updateData, context, apiRouteMap, dispatch]);
  

  const handleRowModesModelChange = useCallback((newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  }, []);

  const currentColumns = useMemo(() => {
    if (!context?.detail?.columns) return [];

    // Define actions column
    const actionsColumn: GridColDef = {
      field: 'actions',
      type: 'actions',
      renderCell: (params: any) => {
        // Return null if no context or actions defined
        if (!context?.detail?.actions) {
          return null;
        }

        const { id } = params;
        if (rowModesModel[id] && rowModesModel[id].mode === GridRowModes.Edit) {
          return (
            <div style={{ display: 'flex', gap: '8px' }}>
              <GridActionsCellItem
                key="cancel"
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="primary"
              />
            </div>
          );
        }
        return (
          <div style={{ display: 'flex', gap: '8px' }}>
            {Object.entries(context.detail.actions).map(([key, value]) => {
              if (key === 'exportable' && value) {
                return (
                  <GridActionsCellItem
                    key="export"
                    icon={<GetAppSharpIcon />}
                    label="Export"
                    className="textPrimary"
                    onClick={() => console.log(id)}
                    color="primary"
                  />
                );
              } else if (key === 'deletable' && value) {
                return (
                  <GridActionsCellItem
                    key="delete"
                    icon={<DeleteForeverSharpIcon />}
                    label="Delete"
                    className="textPrimary"
                    onClick={() => handleConfirmDeletion(id)}
                    color="primary"
                  />
                );
              }
              return null;
            })}
          </div>
        );
      },
    };
    

    // Only add actions column if actions exist and at least one action is enabled
    const hasActions = context?.detail?.actions && 
      Object.values(context.detail.actions).some(value => value === true);

    const columns = hasActions
      ? [actionsColumn, ...context.detail.columns]
      : context.detail.columns;

    return columns;
  }, [context, rowModesModel, handleCancelClick, handleConfirmDeletion]);

  const getDetailPanelContent = useCallback(({ row }: any) => {
    if (!context?.detail?.actions?.expandable) return null;
    const rowData = response?.data?.find((r: any) => r._id === row._id);
    const items = rowData?.startItems || rowData?.order_items || [];

    return (
      <PanelContent
        tagName={tagName}
        context={context}
        row={rowData}
        routeSegment={context?.route}
        columns={context?.panel?.columns || []}
        items={items}
        currentTabIndex={currentTabIndex}
      />
    );
  }, [context, response?.data, currentTabIndex, tagName]);

  const handleRowDoubleClick = (params: any, event: any) => {
    event.preventDefault();
    const currentRow = response?.data?.find((row: any) => row._id === params.id);
    if (!currentRow) {
      console.log("No row found");
      return;
    }

    let navLink = "";
    switch(context?.route){
      case "/starts":
        navLink = context?.redirectUrl(currentRow?.project_id, currentRow?._id);
        break;
      case "/warehouse-orders":
        navLink = context?.redirectUrl(currentRow?.project_id, currentRow?.start_id);
        break;
      case "/projects":
        navLink = context?.redirectUrl(currentRow?._id);
        break;
      default: 
        break;
    }
    navigate(navLink);
    
  };

  return (
    <Grid container justifyContent="center" alignItems="flex-end" className={styles.outer}>
      {context?.header &&
        <StartHeader editable={true} context={context} />
      }

      {/* Tabs */}
      <Grid item xs={12} justifyContent="start" sx={{height: "auto"}}>
        <Tabs
          ref={tabsRef}
          value={currentTabIndex}
          defaultValue={0}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {tabs?.map((tab: any, index: number) => (
            <Tab key={index} label={tab?.label || "Default Label"} value={tab.index} sx={{fontWeight: 700}} />
          ))}
        </Tabs>
      </Grid>

      {/* Conditional Content */}
      {context?.detail?.hasDocuments ? (
        <Grid item justifyContent="center" alignItems="flex-start" sx={{ height: "800px", width: "100%" }}>
          <DocumentView context={context} routeSegment={routeSegment} tagName={tagName} />
        </Grid>
      ) : (
        <Grid item sm={12} xs={12} sx={{ flexGrow: 1, height: '100%', width: '100%'}}>
          <DataGridPremium
            sx={{
              fontSize: 14,
              fontWeight: 600,
              opacity: 0.9,
              maxHeight: '88vh',
              width: '100%',
              flexGrow: 1,
              border: "1px solid rgba(255,255,255,0.5)",
              '& .MuiDataGrid-row:hover': {
                //backgroundColor: theme.palette.primary.main,
                opacity: "0.8"
              },
              '& .MuiDataGrid-columnHeader': {
                whiteSpace: 'normal',
                lineHeight: '1.5',
                wordWrap: 'break-word',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: "bold",
                whiteSpace: 'normal',
                lineHeight: '1',
                wordWrap: 'break-word',
                textOverflow: 'ellipsis',
              },
            }} 
            initialState={{
              columns: {
                columnVisibilityModel: {
                  subjob_and_job_type: false
                }
              }
            }}
            key={context}
            editMode="row"
            getRowId={(row: any) => row?._id || row?.id || row?.order_id}
            density="compact"
            rows={response?.data || []}
            columns={currentColumns}
            loading={isLoading}
            rowHeight={52}
            rowBuffer={10}
            rowModesModel={rowModesModel}
            onRowDoubleClick={handleRowDoubleClick}
            pagination
            paginationMode="server"
            rowCount={response?.total || 0}
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
            pageSizeOptions={[50, 100, 250]}
            filterMode="server"
            filterModel={filterModel}
            onFilterModelChange={onFilterChange}

            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={(error: any) => console.log(error)}
            slots={{
              toolbar: context?.detail?.toolbar,
              detailPanelExpandIcon: KeyboardArrowDownSharpIcon,
              detailPanelCollapseIcon: KeyboardArrowUpSharpIcon,
            }}
            slotProps={{ toolbar: { 
              ...context?.detail?.toolbarProps,
              row: activeRow,
              columns: currentColumns,
              context,
              tagName,
              isUpdateMode: true,
              ...(context?.detail?.actions?.deletable ? { handleDeleteAll } : {}),
              options: context?.detail?.toolbarProps?.options || {
                showHandleDeleteAll: context?.detail?.actions?.deletable,
                showQuickFilter: true,
                showPrintOptions: true,
                showExpandAll: context?.detail?.actions?.expandable,
              },
              expandedRowIds: detailPanelExpandedRowIds,
              rows: response?.data || [],
              setExpandedRowIds: setDetailPanelExpandedRowIds,
            } }}
            getDetailPanelHeight={context?.detail?.actions?.expandable ? getDetailPanelHeight : undefined}
            getDetailPanelContent={context?.detail?.actions?.expandable ? getDetailPanelContent : undefined}
            detailPanelExpandedRowIds={context?.detail?.actions?.expandable ? detailPanelExpandedRowIds : undefined}
            onDetailPanelExpandedRowIdsChange={context?.detail?.actions?.expandable ? handleDetailPanelExpandedRowIdsChange : undefined}
            getCellClassName={(params: GridCellParams<any, any, number>) => {
              return params.isEditable ? `${styles.editableCell}` : '';
            }}
            getRowClassName={(params) =>
              detailPanelExpandedRowIds.includes(params.id) ? `${styles.highlighted}` : ''
            }
          />
        </Grid>
      )}
      <ConfirmationDelete row={activeRow} open={open} setOpen={setOpen} handleDelete={handleDelete} />

    </Grid>
  );
}

export default DetailView;
